import {
    CONNECT_API_CALL_REQUEST,
    CONNECT_API_GET_CASE_OVERVIEW_REQUEST,
    CONNECT_API_GET_CASE_PROPORTION_REQUEST,
    CONNECT_API_GET_CASE_POPULARITY_REQUEST,
    CONNECT_API_GET_MESSAGE_COUNT_REQUEST,
    CONNECT_API_GET_CASE_STATUS_REQUEST,
    CONNECT_API_GET_CASETIME_OVERTIME_REQUEST,
    CONNECT_API_GET_CASE_HANDLING_RESOLVED_COUNT_REQUEST,
    CONNECT_API_GET_CASE_HANDLING_MESSAGE_REQUEST,
    CONNECT_API_GET_REPLY_AVG_COUNT_REQUEST,
    CONNECT_API_GET_CASE_SATISFACTION_REQUEST,
    CONNECT_API_GET_MANAGEMENT_CASE_TYPE_REQUEST,
    CONNECT_API_GENERAL_REQUEST,
    CONNECT_API_GET_SUMMARY_TABLE_REQUEST,
    CONNECT_API_GET_SUMMARY_TABLE_UUID_REQUEST,
    CONNECT_USERS_UUID_STATUS_REQUEST,
    CONNECT_GENERAL_UUID_STATUS_REQUEST,
    CONNECT_SET_LOADING
} from '../../constants/ActionTypes';


/**** Clients : To fetch all the clients info based on client ****/
export function getConnectClient(payload) {
    return {
        type: CONNECT_API_CALL_REQUEST,
        payload
    };
};

/**** Clients : To fetch all the clients info based on client ****/
export function getConnectUserUUIDStatus(payload) {
    return {
        type: CONNECT_USERS_UUID_STATUS_REQUEST,
        payload
    };
};

export function getCaseOverview(payload) {
    return {
        type: CONNECT_API_GET_CASE_OVERVIEW_REQUEST,
        payload
    };
};
export function getCaseProportion(payload) {
    return {
        type: CONNECT_API_GET_CASE_PROPORTION_REQUEST,
        payload
    };
};
export function getCasePopularity(payload) {
    return {
        type: CONNECT_API_GET_CASE_POPULARITY_REQUEST,
        payload
    };
};

export function getMessageCount(payload) {
    return {
        type: CONNECT_API_GET_MESSAGE_COUNT_REQUEST,
        payload
    };
};
export function getCaseStatus(payload) {
    return {
        type: CONNECT_API_GET_CASE_STATUS_REQUEST,
        payload
    };
};

export function getCaseTypeOverTime(payload) {
    return {
        type:CONNECT_API_GET_CASETIME_OVERTIME_REQUEST,
        payload
    }
}

export function getCaseResolvedCount(payload) {
    return {
        type:CONNECT_API_GET_CASE_HANDLING_RESOLVED_COUNT_REQUEST,
        payload
    }
}

export function getCaseHandlingMessageCount(payload) {
    return {
        type:CONNECT_API_GET_CASE_HANDLING_MESSAGE_REQUEST,
        payload
    }
}
export function getCaseHandlingReplyAvgCount(payload) {
    return {
        type:CONNECT_API_GET_REPLY_AVG_COUNT_REQUEST,
        payload
    }
}
export function getCaseHandlingCaseSatisfaction(payload) {
    return {
        type:CONNECT_API_GET_CASE_SATISFACTION_REQUEST,
        payload
    }
}

export function getManagementTypePerfoance(payload) {
    return {
        type:CONNECT_API_GET_MANAGEMENT_CASE_TYPE_REQUEST,
        payload
    }
}

export function getConnectGeneral(payload) {
    return {
        type:CONNECT_API_GENERAL_REQUEST,
        payload
    }
}

export function getConnectGeneralUUIDStatus(payload) {
    return {
        type:CONNECT_GENERAL_UUID_STATUS_REQUEST,
        payload
    }
}

export function getSummaryTableData(payload) {
    return {
        type:CONNECT_API_GET_SUMMARY_TABLE_REQUEST,
        payload
    }
}

export function getSummaryTableDataUUID(payload) {
    return {
        type:CONNECT_API_GET_SUMMARY_TABLE_UUID_REQUEST,
        payload
    }
}

export const setConnectLoading = (isLoading) => {
    return {
      type: CONNECT_SET_LOADING,
      payload: isLoading
    };
  };
  
  


