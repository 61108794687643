import React from "react";
import IntlMessages from "../../util/IntlMessages";
import CircularProgress from "@mui/material/CircularProgress";

const IconListItem = ({
  iconClass,
  headerText,
  subHeaderText,
  subHeaderContent,
  showSpinner,
}) => {
  return (
    <div>
      <span className={"float_right"}>
        {headerText && headerText !== "" && (
          <div className={"menu_popover_item_header"}>
            <IntlMessages id={headerText} />
          </div>
        )}
        {subHeaderText && subHeaderText !== "" && (
          <div className={"menu_popover_item_sub_header"}>
            <IntlMessages id={subHeaderText} />
          </div>
        )}
        {subHeaderContent && subHeaderContent}
      </span>

      {showSpinner && (
        <CircularProgress size={20} color="inherit" sx={{ mr: 1.5 }} />
      )}
      {!showSpinner && iconClass && iconClass !== "" && (
        <span className={"menu_popover_item_icon " + iconClass} />
      )}
    </div>
  );
};

export default IconListItem;
