import {
    CONNECT_API_CALL_REQUEST,
    CONNECT_API_CALL_SUCCESS,
    CONNECT_API_CALL_FAILURE,
    CONNECT_API_GET_CASE_OVERVIEW_REQUEST,
    CONNECT_API_GET_CASE_OVERVIEW_SUCCESS,
    CONNECT_API_GET_CASE_OVERVIEW_FAILURE,
    CONNECT_API_GET_CASE_PROPORTION_REQUEST,
    CONNECT_API_GET_CASE_PROPORTION_SUCCESS,
    CONNECT_API_GET_CASE_PROPORTION_FAILURE,
    CONNECT_API_GET_CASE_POPULARITY_REQUEST,
    CONNECT_API_GET_CASE_POPULARITY_SUCCESS,
    CONNECT_API_GET_CASE_POPULARITY_FAILURE,
    CONNECT_API_GET_MESSAGE_COUNT_REQUEST,
    CONNECT_API_GET_MESSAGE_COUNT_SUCCESS,
    CONNECT_API_GET_MESSAGE_COUNT_FAILURE,
    CONNECT_API_GET_CASE_STATUS_REQUEST,
    CONNECT_API_GET_CASE_STATUS_SUCCESS,
    CONNECT_API_GET_CASE_STATUS_FAILURE,
    CONNECT_API_GET_CASETIME_OVERTIME_REQUEST,
    CONNECT_API_GET_CASETIME_OVERTIME_SUCCESS,
    CONNECT_API_GET_CASETIME_OVERTIME_FAILURE,
    CONNECT_API_GET_CASE_HANDLING_RESOLVED_COUNT_REQUEST,
    CONNECT_API_GET_CASE_HANDLING_RESOLVED_COUNT_SUCCESS,
    CONNECT_API_GET_CASE_HANDLING_RESOLVED_COUNT_FAILURE,
    CONNECT_API_GET_CASE_HANDLING_MESSAGE_REQUEST,
    CONNECT_API_GET_CASE_HANDLING_MESSAGE_SUCCESS,
    CONNECT_API_GET_CASE_HANDLING_MESSAGE_FAILURE,
    CONNECT_API_GET_REPLY_AVG_COUNT_REQUEST,
    CONNECT_API_GET_REPLY_AVG_COUNT_SUCCESS,
    CONNECT_API_GET_REPLY_AVG_COUNT_FAILURE,
    CONNECT_API_GET_CASE_SATISFACTION_REQUEST,
    CONNECT_API_GET_CASE_SATISFACTION_SUCCESS,
    CONNECT_API_GET_CASE_SATISFACTION_FAILURE,
    CONNECT_API_GET_MANAGEMENT_CASE_TYPE_REQUEST,
    CONNECT_API_GET_MANAGEMENT_CASE_TYPE_SUCCESS,
    CONNECT_API_GET_MANAGEMENT_CASE_TYPE_FAILURE,
    CONNECT_API_GENERAL_REQUEST,
    CONNECT_API_GENERAL_SUCCESS,
    CONNECT_API_GENERAL_TYPE_FAILURE,

    CONNECT_API_GET_SUMMARY_TABLE_REQUEST,
    CONNECT_API_GET_SUMMARY_TABLE_SUCCESS,
    CONNECT_API_GET_SUMMARY_TABLE_FAILURE, CONNECT_API_GET_SUMMARY_TABLE_UUID_SUCCESS, CONNECT_API_GET_SUMMARY_TABLE_UUID_FAILURE, CONNECT_API_GET_SUMMARY_TABLE_UUID_REQUEST, CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA_SUCCESS, CONNECT_USERS_UUID_STATUS_REQUEST_SUCCESS, CONNECT_USERS_UUID_STATUS_REQUEST_FAILURE, CONNECT_GENERAL_UUID_STATUS_REQUEST_SUCCESS, AUTH_UPDATE_MENU_CONFIGURATION,
    CONNECT_SET_LOADING
} from '../../constants/ActionTypes';
import {getSummaryTableDataUUID} from "../../actions/connect";
import {CONNECT_REPORTS_DATA, CONNECT_REPORTS_DOWNLOAD, HTTP_STATUS_CODE, HTTP_UUID_STATUS, UUID_STATUS} from "../../constants/config";
import AppUtils from "../../util/util";
import {HTTP_REQUEST_STATUS} from "../../constants/constants";
import {
    REPORT_FILTERS_CONNECT_UPDATE_DATE_RANGE,
    REPORT_FILTERS_CONNECT_SET_DATE_RANGE
} from '../../constants/actions/reports/actionsReportFilters';

let selectionRange = AppUtils.getDefaultDateRangeSelection();
let timeResult = AppUtils.getReportsDates();

const connectDataFilters = [
    {
        label: "DIC_REPORTS_CONNECT_INCOMING",
        value: CONNECT_REPORTS_DATA.IN_COMING,
        icon: 'chartIcon'
    },   
    {
        label: "DIC_REPORTS_CONNECT_MANAGEMENT",
        value: CONNECT_REPORTS_DATA.OUT_GOING,
        icon: 'chartIcon'
    },
    {
        label: "DIC_REPORTS_CONNECT_SUMMARY_TABLE",
        value: CONNECT_REPORTS_DATA.SUMMARY_TABLE,
        icon: "tableIcon"
    }
];

const connectDownloadFilters = [
    {
        id : "CONNECT_PDF_DOWNLOAD",
        label: "DIC_REPORTS_CONNECT_PDF_DOWNLOAD",
        value: CONNECT_REPORTS_DOWNLOAD.DOWNLOAD_PDF,
        icon: "chartIcon",
        isConfig : false
    },
    {
        id : "CONNECT_XLS_DOWNLOAD",
        label: "DIC_REPORTS_CONNECT_SUMMARY_TABLE_XLS_DOWNLOAD",
        value: CONNECT_REPORTS_DOWNLOAD.DOWNLOAD_SUMMARY_TABLE_DATA,
        icon: "tableIcon",
        isConfig : false
    },
    {
        id : "CONNECT_RAW_DATA_DOWNLOAD",
        label: "DIC_REPORTS_CONNECT_RAW_DATA_DOWNLOAD",
        value: CONNECT_REPORTS_DOWNLOAD.DOWNLOAD_RAW_DATA,
        icon: "excelIcon",
        isConfig : true
    }
];

const initialState = {
    companyUsers: {
        "client_id": "",
        "participants": 0,
        "users": 0,
        "active_users": {},
        "smartphone_owners": {}
    },
    caseOverView: {},
    caseProportion: {
        "top_10": [],
        "top_case_type": null,
        "least_frequently": null
    },
    casePopularity: {},
    messageCount: {},
    caseStatus: {},
    caseTypeOverTIme: {},
    caseResolvedCount: {},
    caseHandlingMessageCount: {},
    replyAvgCount: {},
    caseSatisfaction: {},
    managementPerfomance: {},
    connectGeneral: {},
    isTabClickEnabled: false,
    connectSummaryTable: {},
    connectSummaryTableUUID: "",
    connectSummaryTableUUIDCounter: 0,
    summaryTableUUIDInProgress: false,
    connectUsersUUIDStatus: "",
    connectUsersUUIDStatusCounter : 0,
    connectGeneralUUIDStatus: "",
    connectGeneralUUIDStatusCounter : 0,
    connectSelectionRange: selectionRange,
    reportStartDate: timeResult['start_date'],
    reportEndDate: timeResult['end_date'],
    reportEndDateStr: timeResult['end_date_str'],
    reportStartDateStr: timeResult['start_date_str'],
    connectRefreshCounter: 0,
    connectDataFilters: connectDataFilters,
    connectDownloadFilters: [],
    isLoading: false,
    isUsersUuidStatusLoading: false,
    isGeneralUuidStatusLoading: false,
    isSummaryTableUuidStatusLoading: false
};


const ConnectReducers = (state = initialState, action) => {
    const {
        connectData,
        caseOverView,
        caseProportion,
        casePopularity,
        messageCount,
        caseStatus,
        caseTypeOverTIme,
        caseResolvedCount,
        caseHandlingMessageCount,
        replyAvgCount,
        caseSatisfaction,
        managementPerfomance,
        connectGeneral,
        connectSummaryTable
    } = action;
    switch (action.type) {

        case AUTH_UPDATE_MENU_CONFIGURATION:{
            return {
                ...state,
                connectDownloadFilters : updateConnectDownloadFilters(connectDownloadFilters)
            }
        }

        case CONNECT_API_CALL_REQUEST:
        return { ...state, fetching: true, error: null, isTabClickEnabled : false, isLoading: true, isUsersUuidStatusLoading: true };

        case CONNECT_USERS_UUID_STATUS_REQUEST_SUCCESS:
        case CONNECT_API_CALL_SUCCESS: {

            switch ( action.payload.status ) {
                case UUID_STATUS.SUCCESS:{

                    let data = action.payload.data;

                    if (data.data){
                        data = data.data;
                    }

                    return {
                        ...state,
                        fetching: false,
                        isTabClickEnabled : true,
                        companyUsers:data,
                        connectUsersUUIDStatus: "",
                        connectUsersUUIDStatusCounter: 0,
                        isLoading: false,
                        isUsersUuidStatusLoading: false
                    }
                }
                case UUID_STATUS.IN_PROGRESS:{

                    return {
                        ...state,
                        connectUsersUUIDStatus: action.payload.uuid,
                        connectUsersUUIDStatusCounter: state.connectUsersUUIDStatusCounter + 1,
                        isUsersUuidStatusLoading: true
                    }
                }
                case UUID_STATUS.NOT_FOUND:{
                    return {
                        ...state,
                        fetching: false,
                        isTabClickEnabled : true,
                        connectUsersUUIDStatus: "",
                        connectUsersUUIDStatusCounter: 0,
                        isUsersUuidStatusLoading: false
                    }
                }
            }
            break;
        }

        case CONNECT_USERS_UUID_STATUS_REQUEST_FAILURE:
        case CONNECT_API_CALL_FAILURE:
            return {...state, fetching: false, error: action.error, isLoading: false, isUsersUuidStatusLoading: false};
        case CONNECT_API_GET_CASE_OVERVIEW_REQUEST:
            return {...state, fetching: true, error: null};
        case CONNECT_API_GET_CASE_OVERVIEW_SUCCESS:
            return {...state, fetching: false, caseOverView: caseOverView.data, error: null, isLoading: false};
        case CONNECT_API_GET_CASE_OVERVIEW_FAILURE:
            return {...state, fetching: false, error: action.error, isLoading: false};
        case CONNECT_API_GET_CASE_PROPORTION_REQUEST:
            return {...state, fetching: true, error: null};
        case CONNECT_API_GET_CASE_PROPORTION_SUCCESS:{
    
            try{
                for( let i = 0 ; i < caseProportion.data["top_case_type"].length; i++){
                    for( let j = 0 ; j < caseProportion.data["least_frequently"].length; j++ ) {
                        if ( caseProportion.data["top_case_type"][i]["case_type_id"] === caseProportion.data["least_frequently"][j]["case_type_id"]){
                            caseProportion.data["least_frequently"].splice(j, 1);
                            break;
                        }
                    }
                }
            }catch (e) {
            }
            
            return {
                ...state,
                fetching: false,
                caseProportion: caseProportion.data,
                error: null,
                isLoading: false
            };
        }
        
        case CONNECT_API_GET_CASE_PROPORTION_FAILURE:
            return {
                ...state, fetching: false, caseProportion: {
                    "top_10": [],
                    "top_case_type": null,
                    "least_frequently": null
                }, error: action.error, isLoading: false
            };
        case CONNECT_API_GET_CASE_POPULARITY_REQUEST:
            return {...state, fetching: true, error: null};
        case CONNECT_API_GET_CASE_POPULARITY_SUCCESS:
            return {...state, fetching: false, casePopularity: casePopularity.data, error: null, isLoading: false};
        case CONNECT_API_GET_CASE_POPULARITY_FAILURE:
            return {...state, fetching: false, error: action.error, isLoading: false};
        case CONNECT_API_GET_MESSAGE_COUNT_REQUEST:
            return {...state, fetching: true, error: null};
        case CONNECT_API_GET_MESSAGE_COUNT_SUCCESS:
            return {...state, fetching: false, messageCount: messageCount, error: null, isLoading: false};
        case CONNECT_API_GET_MESSAGE_COUNT_FAILURE:
            return {
                ...state, fetching: false, messageCount: {
                    error: action.error
                }, isLoading: false
            };
        case CONNECT_API_GET_CASE_STATUS_REQUEST:
            return {...state, fetching: true, error: null};
        case CONNECT_API_GET_CASE_STATUS_SUCCESS:
            return {...state, fetching: false, caseStatus: caseStatus, error: null, isLoading: false};
        case CONNECT_API_GET_CASE_STATUS_FAILURE:
            return {...state, fetching: false, error: action.error, isLoading: false};
        case CONNECT_API_GET_CASETIME_OVERTIME_REQUEST:
            return {...state, fetching: true, error: null};
        case CONNECT_API_GET_CASETIME_OVERTIME_SUCCESS:
            return {...state, fetching: false, caseTypeOverTIme: caseTypeOverTIme, error: null, isLoading: false};
        case CONNECT_API_GET_CASETIME_OVERTIME_FAILURE:
            return {
                ...state, fetching: false, caseTypeOverTIme: {
                    error: action.error
                }, isLoading: false
            };

        case CONNECT_API_GET_CASE_HANDLING_MESSAGE_REQUEST:
            return {...state, fetching: true, error: null};
        case CONNECT_API_GET_CASE_HANDLING_MESSAGE_SUCCESS:
            return {...state, fetching: false, caseHandlingMessageCount: caseHandlingMessageCount, error: null, isLoading: false};
        case CONNECT_API_GET_CASE_HANDLING_MESSAGE_FAILURE:
            return {...state, fetching: false, error: action.error, isLoading: false};

        case CONNECT_API_GET_CASE_HANDLING_RESOLVED_COUNT_REQUEST:
            return {...state, fetching: true, error: null};
        case CONNECT_API_GET_CASE_HANDLING_RESOLVED_COUNT_SUCCESS:
            return {...state, fetching: false, caseResolvedCount: caseResolvedCount, error: null, isLoading: false};
        case CONNECT_API_GET_CASE_HANDLING_RESOLVED_COUNT_FAILURE:
            return {...state, fetching: false, error: action.error, isLoading: false};

        case CONNECT_API_GET_REPLY_AVG_COUNT_REQUEST:
            return {...state, fetching: true, error: null};
        case CONNECT_API_GET_REPLY_AVG_COUNT_SUCCESS:
            return {...state, fetching: false, replyAvgCount: replyAvgCount, error: null, isLoading: false};
        case CONNECT_API_GET_REPLY_AVG_COUNT_FAILURE:
            return {...state, fetching: false, error: action.error, isLoading: false};


        case CONNECT_API_GET_CASE_SATISFACTION_REQUEST:
            return {...state, fetching: true, error: null};
        case CONNECT_API_GET_CASE_SATISFACTION_SUCCESS:
            return {...state, fetching: false, caseSatisfaction: caseSatisfaction, error: null, isLoading: false};
        case CONNECT_API_GET_CASE_SATISFACTION_FAILURE:
            return {...state, fetching: false, error: action.error, isLoading: false};

        case CONNECT_API_GET_MANAGEMENT_CASE_TYPE_REQUEST:
            return {...state, fetching: true, error: null};
        case CONNECT_API_GET_MANAGEMENT_CASE_TYPE_SUCCESS:
            return {...state, fetching: false, managementPerfomance: managementPerfomance, error: null, isLoading: false};
        case CONNECT_API_GET_MANAGEMENT_CASE_TYPE_FAILURE:
            return {...state, fetching: false, error: action.error, isLoading: false};

        case CONNECT_API_GENERAL_REQUEST:
            return {...state, fetching: true, error: null, isGeneralUuidStatusLoading: true};

        case CONNECT_API_GENERAL_SUCCESS:
        case CONNECT_GENERAL_UUID_STATUS_REQUEST_SUCCESS:{

            switch ( action.payload.status ) {
                case UUID_STATUS.SUCCESS:{

                    let data = action.payload.data;

                    return {
                        ...state,
                        connectGeneral:data,
                        fetching: false,
                        connectGeneralUUIDStatus: "",
                        connectGeneralUUIDStatusCounter: 0,
                        isLoading: false,
                        isGeneralUuidStatusLoading: false
                    }
                }
                case UUID_STATUS.IN_PROGRESS:{
                    return {
                        ...state,
                        connectGeneralUUIDStatus: action.payload.uuid,
                        connectGeneralUUIDStatusCounter: state.connectGeneralUUIDStatusCounter + 1,
                        isGeneralUuidStatusLoading: true
                    }
                }
                case UUID_STATUS.NOT_FOUND:{
                    return {
                        ...state,
                        fetching: false,
                        connectGeneralUUIDStatus: "",
                        connectGeneralUUIDStatusCounter: 0,
                        isGeneralUuidStatusLoading: false
                    }
                }
            }
            break;
        }

        case CONNECT_API_GENERAL_TYPE_FAILURE:
            return {
                ...state, fetching: false, connectGeneral: {
                    error: action.error
                }, isLoading: false, isGeneralUuidStatusLoading: false
            };


        case CONNECT_API_GET_SUMMARY_TABLE_REQUEST:
            return {
                ...state, connectSummaryTable: {
                    connectSummaryTable: {},
                    fetching: true,
                    error: null,
                    connectSummaryTableUUID: "",
                },
                isSummaryTableUuidStatusLoading: true
            };

        case CONNECT_API_GET_SUMMARY_TABLE_UUID_REQUEST:
            return {
                ...state,
                summaryTableUUIDInProgress: true,
                isSummaryTableUuidStatusLoading: true,
                connectSummaryTable: {
                    fetching: true,
                    connectSummaryTable: {},
                }
            };

        case CONNECT_API_GET_SUMMARY_TABLE_SUCCESS:
        case CONNECT_API_GET_SUMMARY_TABLE_UUID_SUCCESS: {
            if (connectSummaryTable.status === HTTP_UUID_STATUS.SUCCESS) {
                return {
                    ...state,
                    connectSummaryTableUUID: "",
                    connectSummaryTableUUIDCounter: 0,
                    summaryTableUUIDInProgress: false,
                    connectSummaryTable: {
                        fetching: false,
                        connectSummaryTable: parseConnectSummaryData(connectSummaryTable.data),
                    }, error: null, isLoading: false, isSummaryTableUuidStatusLoading: false
                };
            } else if (connectSummaryTable.status === HTTP_UUID_STATUS.IN_PROGRESS) {
                return {
                    ...state,
                    connectSummaryTableUUID: connectSummaryTable.uuid,
                    summaryTableUUIDInProgress: true,
                    isSummaryTableUuidStatusLoading: true,
                    connectSummaryTable: {
                        fetching: true,
                        connectSummaryTable: {},
                    },
                    connectSummaryTableUUIDCounter: state.connectSummaryTableUUIDCounter + 1
                };
            } else if (connectSummaryTable.status === HTTP_UUID_STATUS.PARTIAL) {
                return {
                    ...state,
                    summaryTableUUIDInProgress: false,
                    connectSummaryTableUUIDCounter: state.connectSummaryTableUUIDCounter + 1,
                    connectSummaryTable: {
                        fetching: true,
                        connectSummaryTable: parseConnectSummaryData(connectSummaryTable.data),
                    }, error: null, isLoading: false, isSummaryTableUuidStatusLoading: true
                };
            } else if (connectSummaryTable.status === HTTP_UUID_STATUS.NOT_FOUND) {
                return {
                    ...state,
                    connectSummaryTableUUID: "",
                    connectSummaryTableUUIDCounter: 0,
                    summaryTableUUIDInProgress: false,
                    connectSummaryTable: {
                        fetching: false,
                        connectSummaryTable: parseConnectSummaryData(connectSummaryTable.data),
                    }, error: null, isLoading: false, isSummaryTableUuidStatusLoading: false
                };
            }
        }

        case CONNECT_API_GET_SUMMARY_TABLE_FAILURE:
            return {
                ...state, connectSummaryTable: {
                    connectSummaryTable: {},
                    fetching: false,
                    error: action.error
                }, isLoading: false, isSummaryTableUuidStatusLoading: false,
            };

        case CONNECT_API_GET_SUMMARY_TABLE_UUID_FAILURE:
            return {
                ...state,
                connectSummaryTableUUID: "",
                connectSummaryTableUUIDCounter: 0,
                fetching: false,
                connectSummaryTable: {
                    fetching: false,
                    connectSummaryTable: {},
                },
                summaryTableUUIDInProgress: false,
                error: action.error, isLoading: false, isSummaryTableUuidStatusLoading: false
            };

        /**to update connect report date range */
        case REPORT_FILTERS_CONNECT_UPDATE_DATE_RANGE: {
            return{
                ...state,
                connectSelectionRange: action.payload
            };
        }    

        /** to set connect date range**/
        case REPORT_FILTERS_CONNECT_SET_DATE_RANGE: {

            let timeResult = AppUtils.getReportsDates(action.payload.startDate, action.payload.endDate);

            return {
                ...state,
                reportStartDate: timeResult['start_date'],
                reportEndDate: timeResult['end_date'],
                reportEndDateStr: timeResult['end_date_str'],
                reportStartDateStr: timeResult['start_date_str'],
                connectRefreshCounter: state.connectRefreshCounter + 1
            };
        }

        case CONNECT_SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        default:
            return state;
    }
};


function parseConnectSummaryData ( content ) {

    try{

        let data = content.data;

        if ( data.length > 0 ){

            let caseTypesHeaders = [];

            for ( let i = 0 ; i < data.length; i++ ) {
                let caseTypes = data[i]['DIC_KEY_BACKEND_CONNECT_SUMMARY_TABLE_CASE_TYPES'];

                if ( typeof caseTypes != "undefined" && caseTypes.length > 0 ) {

                    for ( let j = 0 ; j < caseTypes.length; j++ ) {
                        caseTypesHeaders.push({
                            'case_type_id': caseTypes[j]['case_type_id'],
                            'total': 0,
                            'name': caseTypes[j]['name']
                        });
                    }
                    break;
                }
            }

            for ( let i = 0 ; i < data.length; i++ ) {
                if ( data[i]['DIC_KEY_BACKEND_CONNECT_SUMMARY_TABLE_CASE_TYPES'].length === 0 ){
                    data[i]['DIC_KEY_BACKEND_CONNECT_SUMMARY_TABLE_CASE_TYPES'] = caseTypesHeaders;
                }
            }

            content.data = data;
        }

    } catch ( ex ) {
        console.error("parseConnectSummaryData : error : ", ex ) ;
    }

    return content;

}


function updateConnectDownloadFilters(data) {
    let result = [];
    try {
        let isValid;
        let moduleKey;

        for( let i = 0; i < data.length; i++ ) {

            isValid = true;

            if ( data[i]["isConfig"] ) {
                moduleKey = AppUtils.getModuleKeyBasedOnKey(data[i]["id"]);

                if ( moduleKey !== ""){
                    isValid = AppUtils.isModuleHasAccess(moduleKey);
                }
            }

            if ( isValid ) {
                result.push(data[i]);
            }
        }

    } catch (ex) {
        console.error("updateConnectDownloadFilters : error : ", ex);
    }
    return result;
}


export default ConnectReducers;
