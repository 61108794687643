import countryList from "./country";
import { HTTP_TIMEOUT } from "../constants/Urls";
import { store } from "../MainApp";
import IntlMessages from "./IntlMessages";
import TextField from "@material-ui/core/TextField";
import React from "react";
import IntegrationReactSelect from "../components/custom/IntegrationReactSelect";
import Checkbox from "@material-ui/core/Checkbox";
import MultiSelectChipSelect from "../components/custom/MultiSelectChipSelect";
import DatePickers from "../components/custom/DatePickers";
import Switch from "@material-ui/core/Switch";
import { baseServerUrl } from "./keys";
import { Divider } from "@material-ui/core";
import {
  SURVEY_STATUSES_VALUE,
  DEFAULT_LANGUAGE_CODE,
  DEFAULT_LANGUAGE_LABEL,
  SURVEY_INFO_CARD_ITEM_VALUES,
  CONNECT_DEFAULT_TIME_RANGE_DIFF,
  SURVEY_RATING_SCALE_INITIAL_WEIGHTAGE,
  SURVEY_QUESTION_EMOJI_COUNT_CONFIG,
} from "../constants/config";
import {
  SURVEY_OPTIONS_MENU,
  SURVEY_VIEW_ACTIONS,
} from "../reducers/survey/survey/reducerSurvey";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { HTTP_REQUEST_STATUS_CODES } from "../constants/constants";
import {
  SCORE_DISPLAY_COLOR_YELLOW,
  SCORE_DISPLAY_COLOR_GREEN,
  SCORE_DISPLAY_COLOR_BLUE,
  PRIMARY_COLOR_BLUE,
} from "../constants/customUIConfig";
import { FormattedMessage } from "react-intl";
import { SURVEY_SUMMARY_TABLE_REPORT_TYPE_FILTER_VALUE_CONSTANTS } from "../constants/config";
import {
  SURVEY_TEMPLATE_OPTIONS_MENU,
  SURVEY_TEMPLATE_VIEW_ACTIONS,
} from "../reducers/survey/templates/reducerSurveyTemplate";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DownloadButton from "components/DownloadButton";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CLIENT_MODULE_KEYS } from "../constants/modules/moduleConfig";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Input, InputAdornment } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MultiSelectDropDown from "../components/MultiSelectDropDown";
import html2pdf from "html2pdf.js";
import MultiSelectChipDropdown from "../components/LSMultiSelectDropdown";
import moment from "moment";
import StorageHandler from "./storageHandler";
import { WOVO_USER_ROLES } from "../constants/userRoles";
import enUSLocale from "../../src/lngProvider/locales/en_US.json";
import PhoneNumber from "components/custom/PhoneNumber";

const QRCode = require("qrcode.react");
const crc32 = require("crc/cjs/crc32").default;

let supportedLanguages = [
  {
    label: "English",
    value: "en-US",
  },
  {
    label: "Hindi",
    value: "hi",
  },
  {
    label: "Telugu",
    value: "tel",
  },
  {
    label: "Kannada",
    value: "kan",
  },
  {
    label: "Tamil",
    value: "tam",
  },
];

let contactFieldTypes = [
  {
    label: "Text",
    value: "text",
  },
  {
    label: "Number",
    value: "number",
  },
  {
    label: "Date",
    value: "date",
  },
];

var AppUtils = {
  selectedItem: {},
  countryMap: {},
  countryInit: false,
  languagesMap: {},
  languagesInit: false,
  customFiledMap: {},
  customFiledInit: false,
  clientChangeListeners: {},
  filteredClients: [],
  surveyResponseSearchParams: "",
  currentRole: "",
  moduleConfig: [],
  selectedClient: {},
  selectClientModules: {
    surveyReport: false,
    surveyReportRawData: false,
    questionnaireReport: false,
    questionnaireReportRawData: false,
  },
  currentTranslations: [],
  defaultTranslations: enUSLocale,
  currentLanguage: DEFAULT_LANGUAGE_CODE,
  allClientsMap: {},

  setAllClients: function(allClientsMap) {
    try {
      for (let i = 0; i < allClientsMap.length; i++) {
        AppUtils.allClientsMap[allClientsMap[i]["value"]] = allClientsMap[i];
      }
    } catch (ex) {
      console.error("AppUtils : setAllClients : error : ", ex);
    }
  },

  getClientByClientKey: function(clientKey) {
    if (typeof AppUtils.allClientsMap[clientKey] != "undefined") {
      return AppUtils.allClientsMap[clientKey];
    } else {
      return null;
    }
  },

  addClientChangeListener: function(key, listener) {
    this.clientChangeListeners[key] = listener;
  },

  removeClientChangeListener: function(key) {
    delete this.clientChangeListeners[key];
  },

  updateClientChangeListener: function() {
    for (let item in this.clientChangeListeners) {
      let callback = this.clientChangeListeners[item];
      if (callback) {
        callback();
      }
    }
  },

  setFilteredClients: function(filteredClients) {
    this.filteredClients = filteredClients;
  },

  getFilteredClients: function() {
    return this.filteredClients;
  },

  setSelectedItem: function(item) {
    this.selectedItem = item;
    this.updateClientChangeListener();
  },

  getSelectedClientCode: function() {
    return this.selectedItem.client_id ? this.selectedItem.client_id : "";
  },

  getSelectedClientId: function() {
    return this.selectedItem.client_key
      ? this.selectedItem.client_key
      : this.selectedItem.id !== -1
      ? this.selectedItem.id
      : -1;
  },

  initCountryMap: function() {
    try {
      for (let i = 0; i < countryList.length; i++) {
        this.countryMap[countryList[i]["value"]] = countryList[i]["label"];
      }
    } catch (ex) {}
  },

  initLanguageMap: function() {
    try {
      for (let i = 0; i < supportedLanguages.length; i++) {
        this.languagesMap[supportedLanguages[i]["value"].toUpperCase()] =
          supportedLanguages[i]["label"];
      }
    } catch (ex) {}
  },

  initCustomFiledMap: function() {
    try {
      for (let i = 0; i < contactFieldTypes.length; i++) {
        this.languagesMap[contactFieldTypes[i]["value"].toUpperCase()] =
          contactFieldTypes[i]["label"];
      }
    } catch (ex) {}
  },

  getCountryNameByCode: function(code) {
    if (!this.countryInit) {
      this.countryInit = true;
      this.initCountryMap();
    }
    let codeVal = code.toUpperCase();
    let value = this.countryMap[codeVal];
    return value !== "" && typeof value != "undefined" ? value : code;
  },

  getSupportedLanguages: function() {
    let temp = [];

    for (let item in supportedLanguages) {
      temp.push(supportedLanguages[item]);
    }

    return temp;
  },

  getSupportedLanguagesSecond: function() {
    let temp = [];
    let flag = true;
    for (let item in supportedLanguages) {
      if (flag) {
        flag = false;
        continue;
      }

      temp.push(supportedLanguages[item]);
    }

    return temp;
  },

  getLanguageNameByCode: function(code) {
    if (!this.languagesInit) {
      this.languagesInit = true;
      this.initLanguageMap();
    }
    let codeVal = code.toUpperCase();
    let value = this.languagesMap[codeVal];
    return value !== "" && typeof value != "undefined" ? value : code;
  },

  getCustomFiledNameByCode: function(code) {
    if (!this.customFiledInit) {
      this.customFiledInit = true;
      this.initCustomFiledMap();
    }
    let codeVal = code.toUpperCase();
    let value = this.customFiledMap[codeVal];
    return value !== "" && typeof value != "undefined" ? value : code;
  },

  getContactFieldTypes: function() {
    return contactFieldTypes;
  },

  getValueFromList: function(list, code) {
    let codeValue = code.toUpperCase();

    for (let i = 0; i < list.length; i++) {
      if (list[i].value.toUpperCase() === codeValue) {
        return list[i].label;
      }
    }

    return code;
  },

  getHttpHeaders: function() {
    const accessToken = StorageHandler.getCookie("access_token");
    const useCookieToValidate =
      process.env.REACT_APP_USE_COOKIES_TO_AUTHENTICATE === "true";
    return {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Accept-Language": store.getState().settings.viewLanguage,
        [`${!useCookieToValidate ? "Authorization" : "withCredentials"}`]: `${
          !useCookieToValidate
            ? store.getState().auth.accessToken || `Bearer ${accessToken}`
            : true
        }`,
      },
      timeout: HTTP_TIMEOUT,
    };
  },

  getUserTimeZone: function() {
    let timezone = "";

    try {
      let user = store.getState().auth.user;

      if (user && user.timezone) {
        timezone = user.timezone;
      }
    } catch (err) {
      console.error("getUserTimeZone : error : ", err);
    }
    return timezone;
  },

  setCurrentTranslations: function(translations, currentLanguage) {
    try {
      if (typeof translations[DEFAULT_LANGUAGE_CODE] != "undefined") {
        this.defaultTranslations = translations[DEFAULT_LANGUAGE_CODE].data;
      }

      if (typeof translations[currentLanguage] != "undefined") {
        this.currentTranslations = translations[currentLanguage].data;
      }

      this.currentLanguage = currentLanguage;
    } catch (ex) {
      console.error("setCurrentTranslations : error : ", ex);
    }
  },

  getTranslationFromKey: function(translationKey) {
    let result = "";

    try {
      if (typeof this.currentTranslations[translationKey] != "undefined") {
        result = this.currentTranslations[translationKey];
      }

      if (
        typeof this.defaultTranslations[translationKey] != "undefined" &&
        result === ""
      ) {
        result = this.defaultTranslations[translationKey];
      }
    } catch (ex) {
      console.error("getTranslationFromKey : error : ", ex);
    }

    return result || translationKey;
  },

  isModuleHasAccess: function(module, module_config) {
    let result = false;
    const moduleConfig = module_config || this.moduleConfig;
    try {
      if (this.currentRole !== "") {
        if (this.currentRole === WOVO_USER_ROLES["SUPER_ADMIN"]) {
          result = true;
        } else {
          if (
            typeof this.selectedClient["client_key"] != "undefined" &&
            typeof moduleConfig[this.selectedClient["client_key"]] !=
              "undefined" &&
            moduleConfig[this.selectedClient["client_key"]].includes(module)
          ) {
            result = true;
          }
        }
      }
    } catch (ex) {
      console.error("isModuleHasAccess : error : ", ex);
    }
    return result;
  },

  setModuleConfigData: function(currentRole, moduleConfig, selectedClient) {
    try {
      this.currentRole = currentRole;
      this.moduleConfig = moduleConfig;
      this.selectedClient = selectedClient;

      this.selectClientModules.surveyReport = this.isModuleHasAccess(
        CLIENT_MODULE_KEYS["LS_MODULE_SURVEY_REPORT"]
      );
      this.selectClientModules.surveyReportRawData = this.isModuleHasAccess(
        CLIENT_MODULE_KEYS["LS_MODULE_SURVEY_REPORT_RAW_DATA"]
      );
      this.selectClientModules.questionnaireReport = this.isModuleHasAccess(
        CLIENT_MODULE_KEYS["LS_MODULE_SURVEY_QUESTIONNAIRE_REPORT"]
      );
      this.selectClientModules.questionnaireReportRawData = this.isModuleHasAccess(
        CLIENT_MODULE_KEYS["LS_MODULE_SURVEY_QUESTIONNAIRE_REPORT_RAW_DATA"]
      );
    } catch (ex) {
      console.error("setModuleConfigData : error : ", ex);
    }
  },

  getModuleKeyBasedOnKey: function(key) {
    let moduleKey = "";

    try {
      switch (key) {
        case "MENU_MANUAL_CASE": {
          moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_MANUAL_CASE"];
          break;
        }

        case "MENU_CONNECT": {
          moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_CONNECT"];
          break;
        }

        case "MENU_SURVEY": {
          moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_SURVEY"];
          break;
        }

        case "MENU_E_LEARNING": {
          moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_E_LEARNING"];
          break;
        }

        case "MENU_COMPANY_POST": {
          moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_COMPANY_POST"];
          break;
        }

        case "E_LEARNING_REPORT": {
          moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_E_LEARNING_REPORTS"];
          break;
        }

        case "COMPANY_POST_RAW_DATA_REPORT": {
          moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_COMPANY_POST_REPORTS"];
          break;
        }

        case "COMPANY_POST_RAW_DATA_DOWNLOAD": {
          moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_COMPANY_POST_RAW_DATA"];
          break;
        }

        case "MENU_CONNECT_REPORT": {
          moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_CONNECT_REPORTS"];
          break;
        }

        case "CONNECT_RAW_DATA_DOWNLOAD": {
          moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_CONNECT_REPORTS_RAW_DATA"];
          break;
        }

        case "E_LEARNING_REPORTS_RAW_DATA": {
          moduleKey =
            CLIENT_MODULE_KEYS["LS_MODULE_E_LEARNING_REPORTS_RAW_DATA"];
          break;
        }

        case "MY_WOVO_WC": {
          moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_WORKER_CONTACTS"];
          break;
        }

        case "MY_WOVO_ACCOUNT_INFO_BDM":
        case "MY_WOVO_ACCOUNT_INFO": {
          moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_ACCOUNTS"];
          break;
        }

        case "MENU_PAY_SLIP": {
          moduleKey = CLIENT_MODULE_KEYS["LS_MODULE_PAYSLIP"];
          break;
        }

        default: {
          break;
        }
      }
    } catch (ex) {
      console.error("getModuleKeyBasedOnKey : error : ", ex);
    }

    return moduleKey;
  },

  getFormComponentTitle: function(item) {
    return typeof item["localisation"] != "undefined" &&
      item["localisation"] !== "" ? (
      <span>
        {item["title"] ? (
          item["title"]
        ) : (
          <label
            className={
              typeof item["labelClass"] != "undefined" ? item["labelClass"] : ""
            }
          >
            {item["key"].includes("custom") ? (
              item["localisation"]
            ) : (
              <IntlMessages id={item["localisation"]} />
            )}
          </label>
        )}
        {item["isMandatory"] ? (
          <label className="text-danger" style={item["star_style"]}>
            <sup>{"*"}</sup>
          </label>
        ) : null}

        {item["subLabel"] && (
          <label
            className={
              item["subLabel"]["className"] ? item["subLabel"]["className"] : ""
            }
            onClick={() => {
              if (item["changeCallback"]) {
                item["changeCallback"](item["subLabel"]["key"]);
              }
            }}
          >
            <IntlMessages id={item["subLabel"]["localisation"]} />
          </label>
        )}
      </span>
    ) : null;
  },

  getFormComponent: function(item) {
    switch (item.type) {
      case "multiselect_react_dropdown": {
        return <MultiSelectDropDown data={item} />;
      }

      case "multiselect_react_chips_dropdown": {
        return <MultiSelectChipDropdown params={item} />;
      }

      case "textfield":
        return (
          <div className="form-group" style={item["style"]}>
            {item["variant"] !== "outlined" && this.getFormComponentTitle(item)}
            <TextField
              error={item["isError"]}
              margin="normal"
              fullWidth
              required={item.required}
              label={this.getFormComponentTitle(item)}
              variant={item["variant"]}
              id={item["key"] + "_textfield"}
              value={item["value"]}
              onChange={(event) => {
                if (item.changeCallback) {
                  item.changeCallback(item["key"], event.target.value);
                }
              }}
              onBlur={() => {
                if (item.blurCallback) {
                  item.blurCallback(item["key"]);
                }
              }}
              disabled={item["disabled"]}
              style={
                item["variant"] === "outlined" ? {} : { marginTop: "24px" }
              }
              helperText={
                item["isError"] && (
                  <span style={{ color: "red" }}>{item["isError"]}</span>
                )
              }
            />
          </div>
        );

      case "phone_number":
        return (
          <div className="form-group phone-number-helperText">
            <PhoneNumber
              identifier={item["key"]}
              value={item.value || ""}
              label={item.localisation}
              required={item.required}
              onChange={(phone) => item.changeCallback(item["key"], phone)}
              countryMapping={item.countryMapping}
              handleInvalidPhoneNumber={item.handleInvalidPhoneNumber}
            />
          </div>
        );

      case "icon_text_field":
        return (
          <div className="form-group" style={item["style"]}>
            <Input
              className={
                "borderedIconTextField " +
                (item["className"] ? item["className"] : "")
              }
              id={item["key"]}
              type={"text"}
              fullWidth
              disabled={item["disabled"]}
              value={item["value"]}
              error={item["isError"]}
              onChange={(event) => {
                if (item.changeCallback) {
                  item.changeCallback(item["key"], event.target.value);
                }
              }}
              onBlur={() => {
                if (item.blurCallback) {
                  item.blurCallback(item["key"]);
                }
              }}
              endAdornment={
                item.icon && (
                  <InputAdornment position="end">
                    <IconButton>{item.icon}</IconButton>
                  </InputAdornment>
                )
              }
            ></Input>
          </div>
        );

      case "file":
        return (
          <div className="form-group" style={item["style"]}>
            {this.getFormComponentTitle(item)}
            <input
              type="file"
              id={item["key"]}
              className={"ml-2 mb-2"}
              accept={item["accept"] && item["accept"]}
              onChange={(event) => {
                if (item.changeCallback) {
                  item.changeCallback(item["key"], event);
                }
              }}
              onBlur={() => {
                if (item.blurCallback) {
                  item.blurCallback(item["key"]);
                }
              }}
              disabled={item["disabled"]}
              style={{ marginTop: "24px" }}
              ref={(input) => (this.posterInputElement = input)}
            />
          </div>
        );

      case "textfield_long":
        return (
          <div className="form-group" style={item["style"]}>
            {this.getFormComponentTitle(item)}
            <TextField
              multiline
              rowsMax="4"
              error={item["isError"]}
              margin="normal"
              fullWidth
              id={item["key"]}
              value={item["value"]}
              disabled={item["disabled"]}
              onChange={(event) =>
                item.changeCallback(item["key"], event.target.value)
              }
              onBlur={() => {
                if (item.blurCallback) {
                  item.blurCallback(item["key"]);
                }
              }}
            />
          </div>
        );

      case "switch":
        return (
          <div className="form-group" style={item["style"]}>
            {this.getFormComponentTitle(item)}
            <Switch
              classes={{
                checked: "text-primary",
                bar: "bg-success",
              }}
              checked={item["value"]}
              onChange={(e) => {
                if (item.changeCallback) {
                  item.changeCallback(item["key"], e.target.value);
                }
              }}
              aria-label={item["key"]}
            />
          </div>
        );

      case "dropdown":
        return (
          <div className="form-group" style={item["style"]}>
            {this.getFormComponentTitle(item)}

            <div style={{ paddingTop: "0.75rem" }}>
              <IntegrationReactSelect
                items={item["items"]}
                onChange={item.changeCallback}
                onBlur={(e) => {
                  if (item.handleBlur) {
                    item.handleBlur(item["key"]);
                  }
                }}
                content_id={item["key"]}
                value={item["value"]}
                defaultValue={item["defaultValue"]}
                disabled={item["disabled"]}
                id={item["key"] + "_" + item["type"]}
              />
            </div>
          </div>
        );

      case "checkbox":
        return (
          <div className="form-group" style={item["style"]}>
            <Checkbox
              checked={item["checked"]}
              onChange={(e) => item.changeCallback(item["key"], e.target.value)}
              disabled={item["disabled"]}
            ></Checkbox>{" "}
            <IntlMessages id={item["localisation"]} />
          </div>
        );

      case "chips":
        return (
          <div className="form-group" style={item["style"]}>
            {this.getFormComponentTitle(item)}
            <MultiSelectChipSelect
              id={item["key"]}
              items={item["items"]}
              selectedItems={item["value"]}
              placeholder={<IntlMessages id={item["localisation"]} />}
              callback={item.changeCallback}
              disabled={item["disabled"]}
            />
          </div>
        );

      case "datefield":
        return (
          <div className="form-group" style={item["style"]}>
            {item["variant"] !== "outlined" && this.getFormComponentTitle(item)}
            <DatePickers
              required={item.required}
              defaultValue={item["value"]}
              minDate={item["minDate"]}
              label={item.label}
              variant={item["variant"]}
              value={item["value"]}
              onChange={(e) => {
                if (item.changeCallback) {
                  item.changeCallback(item["key"], e, item["type"]);
                }
              }}
              onBlur={(e) => {
                if (item.handleBlur) {
                  item.handleBlur(item["key"]);
                }
              }}
            />
          </div>
        );

      case "outline_dropdown":
        return (
          <div className="form-group" style={item["style"]}>
            <TextField
              error={item["isError"]}
              margin="normal"
              fullWidth
              required={item.required}
              id={item["id"]}
              value={item["value"]}
              onChange={(event) => {
                if (item.changeCallback) {
                  item.changeCallback(item["key"], event.target.value);
                }
              }}
              onBlur={() => {
                if (item.blurCallback) {
                  item.blurCallback(item["key"]);
                }
              }}
              InputLabelProps={{
                shrink: item["value"] && item["value"] !== "" ? true : false,
                style: item["inputLabelStyle"],
                className: item["labelClassName"],
              }}
              disabled={item["disabled"]}
              select={item["select"]}
              label={item["label"]}
              SelectProps={item["selectProps"]}
              helperText={item["helperText"]}
              variant={item["variant"]}
              size={item["size"]}
              className={item["selectClass"]}
              islength={item["islength"]}
            >
              {item["islength"]
                ? item["select"] &&
                  (item.addNativeSelect
                    ? [
                        { id: "none", key: "none", name: " ", label: " " },
                        ...item["items"],
                      ]
                    : item["items"]
                  ).map((option, index) => {
                    let disabledCategoriesOption =
                      item["disabledCategoriesOption"] &&
                      option["value"] ===
                        SURVEY_SUMMARY_TABLE_REPORT_TYPE_FILTER_VALUE_CONSTANTS.BY_CATEGORY;
                    return (
                      <>
                        {option["key"] && option["key"].includes("custom") ? (
                          <FormattedMessage id={option["label"]} key={index}>
                            {(message) => (
                              <option
                                value={option["value"]}
                                disabled={disabledCategoriesOption}
                                style={{ fontWeight: "600" }}
                              >
                                {" "}
                                {message}{" "}
                              </option>
                            )}
                          </FormattedMessage>
                        ) : (
                          <FormattedMessage id={option["label"]} key={index}>
                            {(message) => (
                              <option
                                value={option["value"]}
                                disabled={disabledCategoriesOption}
                              >
                                {" "}
                                {message}{" "}
                              </option>
                            )}
                          </FormattedMessage>
                        )}
                        {index ===
                          item["items"].length - item["islength"] - 1 && (
                          <Divider />
                        )}
                      </>
                    );
                  })
                : item["select"] &&
                  (item.addNativeSelect
                    ? [
                        { id: "none", key: "none", name: " ", label: " " },
                        ...item["items"],
                      ]
                    : item["items"]
                  ).map((option, index) => {
                    let disabledCategoriesOption =
                      item["disabledCategoriesOption"] &&
                      option["value"] ===
                        SURVEY_SUMMARY_TABLE_REPORT_TYPE_FILTER_VALUE_CONSTANTS.BY_CATEGORY;
                    if (!option["label"] && option.name) {
                      return (
                        <option
                          value={option.id}
                          disabled={disabledCategoriesOption}
                        >
                          {" "}
                          {option.name}
                        </option>
                      );
                    }
                    return (
                      <FormattedMessage id={option["label"]} key={index}>
                        {(message) => (
                          <option
                            value={option["value"]}
                            disabled={disabledCategoriesOption}
                          >
                            {" "}
                            {message}
                          </option>
                        )}
                      </FormattedMessage>
                    );
                  })}
            </TextField>
          </div>
        );

      case "icon_dropdown":
        return (
          <div className="form-group" style={item["style"]}>
            <Select
              id={item["key"]}
              fullWidth={true}
              value={item["value"]}
              onChange={(event) => {
                if (item.changeCallback) {
                  item.changeCallback(item["key"], event.target.value);
                }
              }}
              onOpen={item.selectOpen}
              onClose={item.selectClose}
              disabled={item["disabled"]}
              label={item["label"]}
              variant={item["variant"]}
              defaultValue={item["defaultValue"]}
              disableUnderline={item["disableUnderline"]}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                getContentAnchorEl: null,
                transformOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              }}
            >
              {item["items"].map((option) => {
                let doStyling = item["value"] === option["value"];
                return (
                  <MenuItem
                    disabled={doStyling}
                    key={option["value"]}
                    value={option["value"]}
                  >
                    <span
                      className={`reportFilterDataIcon ${option["icon"]}${
                        doStyling ? "Blue" : ""
                      }`}
                    ></span>
                    <span
                      style={{
                        color:
                          doStyling && item["changeColor"]
                            ? PRIMARY_COLOR_BLUE
                            : "",
                      }}
                    >
                      <IntlMessages id={option["label"]} />
                    </span>
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        );

      case "download_menu":
        return (
          <div className="form-group" style={item["style"]}>
            <Button
              aria-controls={item["key"]}
              aria-haspopup="true"
              onClick={(event) => {
                if (item.menuTitleClick) {
                  item.menuTitleClick(event);
                }
              }}
              style={{ textTransform: "none", alignItems: "flex-end" }}
              disabled={item.disabled}
            >
              <span className="pr-2">
                {item.showSpinner ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <DownloadButton
                    width="20"
                    height="20"
                    colorFill={PRIMARY_COLOR_BLUE}
                  />
                )}
              </span>
              <span style={{ color: item["menuTitleColor"], fontSize: "1rem" }}>
                {item["menuTitle"]}
              </span>
              <span
                style={{ color: item["menuTitleColor"] }}
                className="reportFilterMenuArrow float-right"
              ></span>
            </Button>
            <Menu
              id={item["key"]}
              anchorEl={item["anchorEl"]}
              open={Boolean(item["menuState"])}
              onClose={item.handleRequestClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {item["items"].map((option) => {
                return (
                  <MenuItem
                    key={option["value"]}
                    value={option["value"]}
                    onClick={() => {
                      item.handleRequestClose();
                      item.changeCallback(item["key"], option["value"]);
                    }}
                  >
                    <span
                      className={`reportFilterDataIcon ${option["icon"]}`}
                    ></span>
                    <span>
                      <IntlMessages id={option["label"]} />
                    </span>
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        );

      case "menu_popover": {
        let defaultTitle = "";
        let defaultIcon = "";

        if (typeof item["value"] != "undefined") {
          if (typeof item["value"]["label"] != "undefined") {
            defaultTitle = <IntlMessages id={item["value"]["label"]} />;
          } else if (typeof item["value"]["name"] != "undefined") {
            defaultTitle = item["value"]["name"];
          }
        }

        if (
          typeof item["value"] != "undefined" &&
          typeof item["value"]["activeIcon"] != "undefined"
        ) {
          defaultIcon = item["value"]["activeIcon"];
        }

        return (
          <div className="form-group" style={item["style"]}>
            <Button
              aria-controls={item["key"]}
              aria-haspopup="true"
              style={{ textTransform: "none", alignItems: "flex-end" }}
              onClick={(event) => {
                if (item.menuTitleClick) {
                  item.menuTitleClick(event);
                }
              }}
            >
              {defaultIcon !== "" && (
                <span className="">
                  <span
                    className={
                      "reportFilterDataIcon vertical_center " + defaultIcon
                    }
                  />{" "}
                </span>
              )}
              <span
                className="ml-4 pl-2 pt-1"
                style={{ color: item["menuTitleColor"], fontSize: "1rem" }}
              >
                {defaultTitle}
              </span>
              <span
                style={{ color: item["menuTitleColor"] }}
                className="reportFilterMenuArrow float-right"
              ></span>
            </Button>
            <Menu
              id={item["key"]}
              anchorEl={item["anchorEl"]}
              open={Boolean(item["menuState"])}
              onClose={item.handleRequestClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              {item["items"].map((option) => {
                return (
                  <MenuItem
                    key={option["value"]}
                    value={option["value"]}
                    onClick={() => {
                      if (item.handleRequestClose) {
                        item.handleRequestClose();
                      }
                      if (item.changeCallback) {
                        item.changeCallback(item["key"], option);
                      }
                    }}
                  >
                    {option["value"] === item.value["value"] ? (
                      <>
                        <span
                          className={`reportFilterDataIcon  ${option["activeIcon"]}`}
                        ></span>
                        <span className={"ml-1 " + item["menuTitleColor"]}>
                          {" "}
                          {option["label"] ? (
                            <IntlMessages id={option["label"]} />
                          ) : (
                            option["name"]
                          )}{" "}
                        </span>
                      </>
                    ) : (
                      <>
                        <span
                          className={`reportFilterDataIcon ${option["icon"]}`}
                        ></span>
                        <span className="pl-2">
                          {option["label"] ? (
                            <IntlMessages id={option["label"]} />
                          ) : (
                            option["name"]
                          )}{" "}
                        </span>
                      </>
                    )}
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        );
      }

      case "border_button_danger": {
        return (
          <Button
            disabled={item["disabled"]}
            variant="contained"
            className={"jr-btn text-white button_primary_border"}
            onClick={() => {
              if (item["callback"]) {
                item["callback"]();
              }
            }}
          >
            <span className="p-1 button_text_color_danger">
              {item["label_id"] ? (
                <IntlMessages id={item["label_id"]} />
              ) : item["label"] ? (
                item["label"]
              ) : (
                ""
              )}
            </span>
          </Button>
        );
      }

      case "border_button_info": {
        return (
          <Button
            disabled={item["disabled"]}
            variant="contained"
            className={"jr-btn text-white button_primary_border"}
            onClick={() => {
              if (item["callback"]) {
                item["callback"]();
              }
            }}
          >
            <span
              className={
                "p-1 " +
                (item["disabled"]
                  ? "button_text_color_disabled"
                  : "button_text_color_primary")
              }
            >
              {item["label_id"] ? (
                <IntlMessages id={item["label_id"]} />
              ) : item["label"] ? (
                item["label"]
              ) : (
                ""
              )}
            </span>
          </Button>
        );
      }

      case "no_border_button_info": {
        return (
          <Button
            disabled={item["disabled"]}
            className={"jr-btn text-white no_button_primary_border"}
            onClick={() => {
              if (item["callback"]) {
                item["callback"]();
              }
            }}
          >
            <span
              className={
                "p-1 " +
                (item["disabled"]
                  ? "button_text_color_disabled"
                  : "button_text_color_primary")
              }
            >
              {item["label_id"] ? (
                <IntlMessages id={item["label_id"]} />
              ) : item["label"] ? (
                item["label"]
              ) : (
                ""
              )}
            </span>
          </Button>
        );
      }

      case "button_primary": {
        return (
          <Button
            disabled={item["disabled"]}
            variant="contained"
            className={"jr-btn text-white button_primary"}
            onClick={() => {
              if (item["onClick"]) {
                item["onClick"]();
              }
            }}
          >
            <span className="p-1 button_text_color_white">
              {item["label_id"] ? (
                <IntlMessages id={item["label_id"]} />
              ) : item["label"] ? (
                item["label"]
              ) : (
                ""
              )}
            </span>
          </Button>
        );
      }

      default:
        return null;
    }
  },

  getSurveyPoster: function(currentSurvey, imgType) {
    try {
      if (
        currentSurvey != null &&
        typeof currentSurvey.posters != "undefined" &&
        currentSurvey.posters != null &&
        currentSurvey.posters.length > 0
      ) {
        for (let i = 0; i < currentSurvey.posters.length; i++) {
          if (currentSurvey.posters[i].type === imgType) {
            return (
              baseServerUrl +
              (currentSurvey.posters[i]["img"]
                ? currentSurvey.posters[i]["img"]
                : currentSurvey.posters[i]["logo"])
            );
          }
        }
      }
    } catch (e) {}

    return "";
  },

  getRandomArray: function(maxValue) {
    let result = [];
    try {
      const tempSet = new Set();
      while (tempSet.size < maxValue) {
        tempSet.add(Math.floor(Math.random() * maxValue) + 1);
      }

      result = Array.from(tempSet);
    } catch (ex) {
      console.error("getRandomArray : error : ", ex);
    }

    return result;
  },

  getRandomizedQuestions: function(questions) {
    let result = [];

    try {
      let randomArray = AppUtils.getRandomArray(questions.length);

      for (let i = 0; i < randomArray.length; i++) {
        result.push(questions[randomArray[i] - 1]);
      }
    } catch (ex) {
      console.error("getRandomizedQuestions : error : ", ex);
    }
    return result;
  },

  getSortedQuestions: function(questions) {
    questions.sort(function(a, b) {
      return new Date(a.created_date) - new Date(b.created_date);
    });

    for (let i = 0; i < questions.length; i++) {
      if (
        typeof questions[i].question_options != "undefined" &&
        questions[i].question_options.length > 0
      ) {
        questions[i].question_options.sort(function(a, b) {
          return new Date(a.created_date) - new Date(b.created_date);
        });
      }
    }

    return questions;
  },

  getLanguageCodeById: function(languages, idVal) {
    let result = "";

    try {
      for (let i = 0; i < languages.length; i++) {
        if (languages[i].id === idVal) {
          return languages[i]["language_code"];
        }
      }
    } catch (e) {}

    return result;
  },

  isClientIsBrand: function(client) {
    let result = false;

    try {
      if (
        client != null &&
        typeof client != "undefined" &&
        typeof client.relation_mapping != "undefined" &&
        client.relation_mapping.length > 0
      ) {
        for (let i = 0; i < client.relation_mapping.length; i++) {
          if (client.relation_mapping[i].relation_type === 1) {
            result = true;
            break;
          }
        }
      }
    } catch (ex) {
      console.log(
        "ReducerClients : isClientIsBrand : ERROR : " + ex.toString()
      );
    }

    return result;
  },

  getDateLabelFromDate: function(dateVal) {
    try {
      if ((dateVal != null) & (dateVal !== "")) {
        let tempDate = new Date(dateVal);
        return (
          tempDate.getDate() +
          " - " +
          (tempDate.getMonth() + 1) +
          " - " +
          tempDate.getFullYear()
        );
      }
    } catch (ex) {
      console.error("util : getDateLabelFromDate : error : ", ex);
    }
    return "";
  },

  getDateLabelFromDateDDMMYYYY: function(dateVal, delimiter) {
    try {
      if ((dateVal != null) & (dateVal !== "")) {
        let tempDate = new Date(dateVal);
        return (
          tempDate.getDate() +
          delimiter +
          (tempDate.getMonth() + 1) +
          delimiter +
          tempDate.getFullYear()
        );
      }
    } catch (ex) {
      console.error("util : getDateLabelFromDate : error : ", ex);
    }
    return "";
  },

  getDateLabelFromDateDDMMYYYYAMPMWithTimeZone: function(
    dateVal,
    delimiter,
    isNewLine
  ) {
    try {
      if ((dateVal != null) & (dateVal !== "")) {
        let tempDate = new Date(dateVal);
        let hours = tempDate.getHours();
        let minutes = tempDate.getMinutes();
        let AMPM = "AM";

        if (hours > 12) {
          hours = hours % 12;
          AMPM = "PM";
        }

        if (hours < 10) {
          hours = "0" + hours;
        }

        if (minutes < 10) {
          minutes = "0" + minutes;
        }

        return (
          tempDate.getDate() +
          delimiter +
          (tempDate.getMonth() + 1) +
          delimiter +
          tempDate.getFullYear() +
          (isNewLine ? ", " : " ") +
          hours +
          ":" +
          minutes +
          " " +
          AMPM
        );
      }
    } catch (ex) {
      console.error("util : getDateLabelFromDate : error : ", ex);
    }
    return "";
  },

  getDateLabelFromDateDDMMYYYYWithTimeZone: function(dateVal, delimiter) {
    try {
      if ((dateVal != null) & (dateVal !== "")) {
        let tempDate = new Date(dateVal);
        return (
          tempDate.getDate() +
          delimiter +
          (tempDate.getMonth() + 1) +
          delimiter +
          tempDate.getFullYear()
        );
      }
    } catch (ex) {
      console.error("util : getDateLabelFromDate : error : ", ex);
    }
    return "";
  },

  getDateTimestampFromDate: function(dateVal) {
    if ((dateVal != null) & (dateVal !== "")) {
      let tempDate = new Date(dateVal);
      return tempDate.getTime() / 1000;
    }

    return "";
  },

  /**
   * accpet date as input and converts that date to UTC timezone date,
   * return the same input value if invalid date is passed
   */
  getDateTimeInUTC: function(dateVal) {
    try {
      return new Date(
        new Date(dateVal.replace(/Z/g, "")).toGMTString()
      ).toISOString();
    } catch (e) {
      return dateVal;
    }
  },

  setSurveyResponseParams: function(searchParams) {
    if (this.surveyResponseSearchParams === "") {
      this.surveyResponseSearchParams = searchParams;
    }
  },

  getSurveyResponseParams: function() {
    return this.surveyResponseSearchParams;
  },

  /** to delete the question to the current survey or template**/
  deleteSurveyQuestion: function(currentItem, deletedQuestion) {
    let newQuestions = currentItem.questions;
    if (
      deletedQuestion !== "" &&
      typeof currentItem != "undefined" &&
      typeof currentItem["questions"] != "undefined"
    ) {
      newQuestions = currentItem["questions"].filter(
        (qt) => qt.id !== deletedQuestion
      );
    }
    newQuestions = newQuestions.filter((qt) => !!qt.id);
    return { ...currentItem, questions: newQuestions };
  },

  /** to add the question to the current survey or template**/
  addSurveyQuestions: function(currentItem, currentQuestion, parentQuestions) {
    try {
      if (
        currentItem != null &&
        typeof currentItem != "undefined" &&
        typeof currentItem.questions != "undefined"
      ) {
        let isNewQuestion = true;

        for (let i = 0; i < currentItem.questions.length; i++) {
          if (currentQuestion.id === currentItem.questions[i].id) {
            currentItem.questions[i] = currentQuestion;
            isNewQuestion = false;
            break;
          }
        }

        if (isNewQuestion) {
          currentItem.questions.push(currentQuestion);
        }

        if (typeof parentQuestions != "undefined") {
          currentItem.questions = [];

          let questions = parentQuestions;

          for (let i = 0; i < questions.length; i++) {
            if (typeof questions[i].id != "undefined") {
              currentItem.questions.push(questions[i]);
            }
          }
        }

        currentItem.questions = AppUtils.getSortedQuestions(
          currentItem.questions
        );
      }
    } catch (ex) {
      console.log("AppUtils : addSurveyQuestions : error : " + ex);
    }

    return currentItem;
  },

  getDateFromDateTime: function(currentDate) {
    let result = currentDate;
    try {
      result = new Date(
        result.getUTCFullYear() +
          "-" +
          (result.getUTCMonth() + 1) +
          "-" +
          result.getUTCDate()
      );
    } catch (ex) {
      console.error("getDateFromDateTime : error : ", ex);
    }

    return result;
  },

  getSurveyURL: function(surveyId) {
    return baseServerUrl + "/survey_response/?id=" + surveyId;
  },

  filterSupportedLanguages: function(currentItem) {
    try {
      let missedLanguages = [];

      if (typeof currentItem["missed_languages"] != "undefined") {
        missedLanguages = currentItem["missed_languages"];
      }

      if (
        missedLanguages.length > 0 &&
        AppUtils.selectedClient.client_key !== currentItem["client_id"]
      ) {
        let supportedLanguages = currentItem["supported_languages"];
        let languageValues = [];

        for (let i = 0; i < supportedLanguages.length; i++) {
          if (
            !missedLanguages.includes(supportedLanguages[i]["language_code"])
          ) {
            languageValues.push(supportedLanguages[i]);
          }
        }

        currentItem["supported_languages"] = languageValues;
      }
    } catch (ex) {
      console.error("filterSupportedLanguages : error : ", ex);
    }
  },

  prepareScoredWeights: function(count) {
    let result = [];
    try {
      let diffValue = SURVEY_RATING_SCALE_INITIAL_WEIGHTAGE / count;

      for (let i = 0; i < count; i++) {
        let value = SURVEY_RATING_SCALE_INITIAL_WEIGHTAGE - diffValue * i;
        value = value % 1 === 0 ? value : value.toFixed(2);
        result.push(value);
      }
    } catch (ex) {
      console.error("prepareScoredWeights : error : ", ex);
    }

    return result;
  },

  /** to get survey question emojis based on passed count **/
  prepareScoredEmojis: function(count) {
    let result = [];
    try {
      let indexValue =
        count <= Object.keys(SURVEY_QUESTION_EMOJI_COUNT_CONFIG).length
          ? count
          : Object.keys(SURVEY_QUESTION_EMOJI_COUNT_CONFIG).length;
      result = SURVEY_QUESTION_EMOJI_COUNT_CONFIG[indexValue];
    } catch (ex) {
      console.error("prepareScoredEmojis : error : ", ex);
    }

    return result;
  },

  /** to update current survey details **/
  updateCurrentSurvey: function(survey) {
    try {
      survey["label_start_date"] = AppUtils.getDateLabelFromDateDDMMYYYY(
        survey["from_date"],
        "/"
      );
      survey["label_end_date"] = AppUtils.getDateLabelFromDateDDMMYYYY(
        survey["to_date"],
        "/"
      );

      if (
        survey["status"] !== SURVEY_STATUSES_VALUE.DRAFT &&
        survey["status"] !== SURVEY_STATUSES_VALUE.ACTIVE
      ) {
        survey["label_end_date"] = AppUtils.getDateLabelFromDateDDMMYYYY(
          survey["to_date"] || survey["modified_date"],
          "/"
        );
        survey["to_date"] = survey["to_date"] || survey["modified_date"];
      }

      let endDate = AppUtils.getDateLabelFromDate(survey["to_date"]);

      if (survey["label_end_date"] !== "") {
        survey["label_end_date"] = "  -  " + survey["label_end_date"];
      }

      survey["label_start_date_timestamp"] = AppUtils.getDateTimestampFromDate(
        survey["from_date"]
      );
      survey["label_end_date_timestamp"] = AppUtils.getDateTimestampFromDate(
        survey["to_date"]
      );

      if (!survey["questions"]) {
        survey["questions"] = [];
      }

      let questionsCount =
        typeof survey["questions_count"] != "undefined"
          ? survey["questions_count"]
          : survey["questions"].length;

      survey["label_question_count"] = questionsCount;
      survey["label_response_count"] = 0;
      const label_status_sub_value_map = {
        0: "DIC_SURVEY_STATUS_SCHEDULED",
        1: "DIC_KEY_PAY_SLIP_FILTER_SENDING",
        3: "DIC_KEY_PAY_SLIP_FILTER_CANCELLED",
      };
      // 2 : SENT
      let label_status_value = "";
      let label_status_sub_value =
        typeof survey["invite_status"] !== "undefined" &&
        label_status_sub_value_map[survey["invite_status"]]
          ? label_status_sub_value_map[survey["invite_status"]]
          : "";
      let label_font_color = "white";
      let label_bg_color = "#3AB55C";
      let label_status_style = "text-white bg-success";
      let badge_status_style = "";
      let optionsMenu = [];
      let optionsMenuAssigned = SURVEY_OPTIONS_MENU.ASSIGNED_DRAFT;

      if (survey.label_end_date_timestamp) {
        const nowDate = new Date().getTime() / 1000;
        const diff = survey.label_end_date_timestamp - nowDate;
        const oneDay = 60 * 60 * 24;
        if (diff > 0 && diff <= oneDay * 7) {
          const days = Math.ceil(diff / oneDay);
          label_status_sub_value = `${days} ${days > 1 ? "days" : "day"} left`;
        }
      }

      let labelStyle = "surveyStatusLabel surveyStatusLabelDraft";

      if (typeof survey["response_count"] != "undefined") {
        survey["response_count"] = this.numberWithCommas(
          survey["response_count"]
        );
      }

      switch (survey["status"]) {
        case SURVEY_STATUSES_VALUE.DRAFT: {
          label_status_value = "DIC_SURVEY_STATUS_DRAFTS";
          label_font_color = "#D6AB41";
          label_bg_color = "#FAF4E6";
          label_status_style = "text-white bg-warning";
          badge_status_style = "draft";

          labelStyle = "surveyStatusLabel surveyStatusLabelDraft";

          optionsMenu = SURVEY_OPTIONS_MENU["DRAFT"];
          optionsMenu = JSON.parse(JSON.stringify(optionsMenu));

          if (questionsCount > 0) {
            optionsMenu.push({
              label: "DIC_SURVEY_SURVEY_NEW_SURVEY_CREATE_URL",
              value: SURVEY_VIEW_ACTIONS.GENERATE_URL,
            });
          }

          break;
        }
        case SURVEY_STATUSES_VALUE.ACTIVE: {
          let startDate = new Date(survey["from_date"]);
          let endDate =
            survey["to_date"] !== "" && survey["to_date"] !== null
              ? new Date(survey["to_date"])
              : -1;

          let today = new Date();

          labelStyle = "surveyStatusLabel surveyStatusLabelActive";

          if (startDate > today) {
            label_status_value = "DIC_SURVEY_STATUS_ACTIVE";
            label_font_color = "#00A080";
            label_bg_color = "#E6FAF6";
            label_status_style = "text-white bg-primary";
            badge_status_style = "scheduled";

            optionsMenu = SURVEY_OPTIONS_MENU["SCHEDULED"];
          } else {
            survey["is_questions_disabled"] = true;

            if (endDate === -1) {
              if (today >= startDate) {
                label_status_value = "DIC_SURVEY_STATUS_ACTIVE";
                label_font_color = "#00A080";
                label_bg_color = "#E6FAF6";
                label_status_style = "text-white bg-success";
                badge_status_style = "active";

                optionsMenu = SURVEY_OPTIONS_MENU["ACTIVE"];
              }
            } else {
              today = AppUtils.getDateFromDateTime(today);
              startDate = AppUtils.getDateFromDateTime(startDate);
              endDate = AppUtils.getDateFromDateTime(endDate);

              if (today >= startDate && today <= endDate) {
                label_status_value = "DIC_SURVEY_STATUS_ACTIVE";
                label_status_style = "text-white bg-success";
                badge_status_style = "active";
                label_font_color = "#00A080";
                label_bg_color = "#E6FAF6";

                optionsMenu = SURVEY_OPTIONS_MENU["ACTIVE"];
              } else if (today > endDate) {
                label_status_value = "DIC_SURVEY_STATUS_COMPLETED";
                label_status_style = "text-white bg-indigo";
                badge_status_style = "completed";
                label_font_color = "#808080";
                label_bg_color = "#EFF1F1";

                optionsMenu = SURVEY_OPTIONS_MENU["COMPLETED"];
              }
            }
            optionsMenuAssigned = SURVEY_OPTIONS_MENU.ASSIGNED_ACTIVE;
          }

          optionsMenu = JSON.parse(JSON.stringify(optionsMenu));

          break;
        }
        case SURVEY_STATUSES_VALUE.INACTIVE: {
          label_status_value = "DIC_SURVEY_STATUS_INACTIVE";
          label_status_style = "text-white bg-dark";
          badge_status_style = "inactive";
          label_font_color = "#808080";
          label_bg_color = "#EFF1F1";

          labelStyle = "surveyStatusLabel surveyStatusLabelClosed";

          optionsMenu = SURVEY_OPTIONS_MENU["INACTIVE"];
          optionsMenu = JSON.parse(JSON.stringify(optionsMenu));
          survey["is_questions_disabled"] = true;
          break;
        }
        case SURVEY_STATUSES_VALUE.SCHEDULED: {
          label_status_value = "DIC_SURVEY_STATUS_ACTIVE";
          label_status_style = "text-white bg-primary";
          badge_status_style = "scheduled";
          label_font_color = "#00A080";
          label_bg_color = "#E6FAF6";

          labelStyle = "surveyStatusLabel surveyStatusLabelActive";

          optionsMenu = SURVEY_OPTIONS_MENU["SCHEDULED"];
          optionsMenu = JSON.parse(JSON.stringify(optionsMenu));
          break;
        }

        case SURVEY_STATUSES_VALUE.DELETED: {
          label_status_value = "DIC_SURVEY_STATUS_DELETED";
          label_status_style = "text-white bg-danger";
          badge_status_style = "deleted";
          label_font_color = "#808080";
          label_bg_color = "#EFF1F1";

          labelStyle = "surveyStatusLabel surveyStatusLabelClosed";

          optionsMenu = [];
          survey["is_questions_disabled"] = true;
          break;
        }

        case SURVEY_STATUSES_VALUE.COMPLETED: {
          label_status_value = "DIC_SURVEY_STATUS_COMPLETED";
          label_status_style = "text-white bg-indigo";
          badge_status_style = "completed";
          label_font_color = "#808080";
          label_bg_color = "#EFF1F1";

          labelStyle = "surveyStatusLabel surveyStatusLabelClosed";

          optionsMenu = SURVEY_OPTIONS_MENU["COMPLETED"];
          optionsMenu = JSON.parse(JSON.stringify(optionsMenu));
          survey["is_questions_disabled"] = true;
          optionsMenuAssigned = SURVEY_OPTIONS_MENU.ASSIGNED_ACTIVE;
          break;
        }

        default: {
          break;
        }
      }

      let tempObj = [];
      tempObj.push({
        label: "DIC_SURVEY_SURVEY_NEW_SURVEY_VIEW",
        value: SURVEY_VIEW_ACTIONS.VIEW,
      });

      if (!AppUtils.selectClientModules.surveyReport) {
        for (let i = 0; i < optionsMenu.length; i++) {
          if (optionsMenu[i]["value"] === SURVEY_VIEW_ACTIONS.QUICK_INSIGHTS) {
            optionsMenu.splice(i, 1);
            break;
          }
        }

        for (let i = 0; i < optionsMenuAssigned.length; i++) {
          if (
            optionsMenuAssigned[i]["value"] ===
            SURVEY_VIEW_ACTIONS.QUICK_INSIGHTS
          ) {
            optionsMenuAssigned.splice(i, 1);
            break;
          }
        }
      }

      for (let i = 0; i < optionsMenu.length; i++) {
        tempObj.push(optionsMenu[i]);
      }

      let optionsViewMenuAssigned = [];
      optionsViewMenuAssigned.push({
        label: "DIC_SURVEY_SURVEY_NEW_SURVEY_VIEW",
        value: SURVEY_VIEW_ACTIONS.VIEW,
      });

      for (let i = 0; i < optionsMenu.length; i++) {
        if (optionsMenu[i].value === SURVEY_VIEW_ACTIONS.QUICK_INSIGHTS) {
          optionsViewMenuAssigned.push({
            label: "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_INSIGHT",
            value: SURVEY_VIEW_ACTIONS.QUICK_INSIGHTS,
          });

          optionsMenu = JSON.parse(JSON.stringify(optionsMenu));
          optionsMenu.splice(i, 1);
          break;
        }
      }

      AppUtils.filterSupportedLanguages(survey);

      for (let i = 0; i < (survey["supported_languages"] || []).length; i++) {
        survey["supported_languages"][i]["value"] =
          survey["supported_languages"][i]["language_code"];
      }

      optionsViewMenuAssigned = optionsViewMenuAssigned.concat(
        optionsMenuAssigned
      );

      tempObj = AppUtils.removeDuplicateOptionsFromSurvey(tempObj);
      optionsMenu = AppUtils.removeDuplicateOptionsFromSurvey(optionsMenu);
      optionsViewMenuAssigned = AppUtils.removeDuplicateOptionsFromSurvey(
        optionsViewMenuAssigned
      );
      optionsMenuAssigned = AppUtils.removeDuplicateOptionsFromSurvey(
        optionsMenuAssigned
      );

      survey["optionsMenu"] = tempObj;
      survey["optionsMenuView"] = optionsMenu;
      survey["optionsViewMenuAssigned"] = optionsViewMenuAssigned;
      survey["optionsMenuAssigned"] = optionsMenuAssigned;

      survey["label_status_value"] = label_status_value;
      survey["label_status_sub_value"] = label_status_sub_value;
      survey["label_font_color"] = label_font_color;
      survey["label_bg_color"] = label_bg_color;
      survey["label_status_style"] = label_status_style;
      survey["badge_status_style"] = badge_status_style;
      survey["labelStyle"] = labelStyle;

      const responsePerDeviceLabel = survey["one_response_per_device"]
        ? "DIC_SURVEY_NEW_SURVEY_LABEL_ONE_RESPONSE"
        : "DIC_SURVEY_NEW_SURVEY_LABEL_MULTIPLE_RESPONSES";

      survey.infoMenu = [
        {
          id: SURVEY_INFO_CARD_ITEM_VALUES.SURVEY_PERIOD,
          title: (
            <IntlMessages id="DIC_SURVEY_NEW_SURVEY_LABEL_SURVEY_PERIOD" />
          ),
          icon: "calendar",
          userList: "",
          desc: [
            AppUtils.getDateLabelFromDate(survey["from_date"]) +
              " - " +
              (endDate !== "" ? endDate : ""),
          ],
        },
        {
          id: SURVEY_INFO_CARD_ITEM_VALUES.NUMBER_OF_QUESTIONS,
          title: <IntlMessages id="DIC_SURVEY_NEW_SURVEY_NO_OF_QUESTIONS" />,
          icon: "view-list-alt",
          userList: "",
          desc: [questionsCount],
        },
        {
          id: SURVEY_INFO_CARD_ITEM_VALUES.LANGUAGES,
          title: <IntlMessages id="DIC_SURVEY_NEW_SURVEY_NO_OF_LANGUAGES" />,
          icon: "globe-alt",
          userList: "",
          desc: [
            survey.supported_languages && survey.supported_languages.length > 0
              ? survey.supported_languages.length
              : 0,
          ],
        },
        // {
        //     id: SURVEY_INFO_CARD_ITEM_VALUES.RESPONSES_PER_DEVICE,
        //     title: <IntlMessages id="DIC_SURVEY_NEW_SURVEY_NO_OF_RESPONSES_PER_DEVICE"/>,
        //     icon: "smartphone",
        //     userList: "",
        //     desc: [responsePerDeviceLabel]
        // },
        {
          id: SURVEY_INFO_CARD_ITEM_VALUES.NUMBER_OF_CATEGORIES,
          title: <IntlMessages id="DIC_SURVEY_NEW_SURVEY_NO_OF_CATEGORIES" />,
          icon: "view-agenda",
          userList: "",
          desc: [
            survey.reporting_categories &&
            survey.reporting_categories.length > 0
              ? survey.reporting_categories.length
              : 0,
          ],
        },
        {
          id: SURVEY_INFO_CARD_ITEM_VALUES.NUMBER_OF_COMPANIES,
          title: <IntlMessages id="DIC_SURVEY_NEW_SURVEY_NO_OF_CLIENTS" />,
          icon: "city-alt",
          userList: "",
          desc: [
            survey.shared_clients.length > 0 ? survey.shared_clients.length : 0,
          ],
        },
      ];

      survey.infoLabels = [
        {
          id: SURVEY_INFO_CARD_ITEM_VALUES.SURVEY_PERIOD,
          title: "DIC_SURVEY_NEW_SURVEY_LABEL_SURVEY_PERIOD",
          icon: "ls_icon_calender",
          desc: survey["label_start_date"] + survey["label_end_date"],
        },
        {
          id: SURVEY_INFO_CARD_ITEM_VALUES.NUMBER_OF_QUESTIONS,
          title: "DIC_SURVEY_NEW_SURVEY_NO_OF_QUESTIONS",
          icon: "ls_icon_questions",
          userList: "",
          desc: questionsCount,
        },
        {
          id: SURVEY_INFO_CARD_ITEM_VALUES.LANGUAGES,
          title: "DIC_SURVEY_NEW_SURVEY_NO_OF_LANGUAGES",
          icon: "ls_icon_languages",
          userList: "",
          desc:
            survey.supported_languages.length > 0
              ? survey.supported_languages.length
              : 0,
        },
        // {
        //     id: SURVEY_INFO_CARD_ITEM_VALUES.RESPONSES_PER_DEVICE,
        //     title: "DIC_SURVEY_NEW_SURVEY_NO_OF_RESPONSES_PER_DEVICE",
        //     icon: "ls_icon_responses_device",
        //     userList: "",
        //     desc: responsePerDeviceLabel
        // },
        {
          id: SURVEY_INFO_CARD_ITEM_VALUES.NUMBER_OF_CATEGORIES,
          title: "DIC_SURVEY_NEW_SURVEY_NO_OF_CATEGORIES",
          icon: "ls_icon_categories",
          userList: "",
          desc:
            survey.reporting_categories &&
            survey.reporting_categories.length > 0
              ? survey.reporting_categories.length
              : 0,
        },
        {
          id: SURVEY_INFO_CARD_ITEM_VALUES.NUMBER_OF_COMPANIES,
          title: "DIC_SURVEY_NEW_SURVEY_NO_OF_CLIENTS",
          icon: "ls_icon_companies",
          userList: "",
          desc:
            survey.shared_clients.length > 0 ? survey.shared_clients.length : 0,
        },
      ];
    } catch (e) {
      console.error(e);
    }

    return survey;
  },

  removeDuplicateOptionsFromSurvey: function(items) {
    let result = [];

    try {
      let is_valid = false;

      for (let i = 0; i < items.length; i++) {
        is_valid = true;

        for (let j = 0; j < result.length; j++) {
          if (items[i]["value"] === result[j]["value"]) {
            is_valid = false;
            break;
          }
        }

        if (is_valid) {
          result.push(items[i]);
        }
      }
    } catch (ex) {
      console.error("removeDuplicateOptionsFromSurvey : ", ex);
    }

    return result;
  },

  filterAllContent: function(prevContent, value) {
    let result = prevContent;

    try {
      let isAllSelected = false;

      for (let i = 0; i < prevContent.length; i++) {
        if (prevContent[i].isAll) {
          isAllSelected = true;
          break;
        }
      }

      result = [];

      for (let i = 0; i < value.length; i++) {
        if (isAllSelected) {
          if (!value[i].isAll || value[i].is_global) {
            result.push(value[i]);
          }
        } else {
          if (value[i].isAll || value[i].is_global) {
            result = [];
            result.push(value[i]);
            break;
          } else {
            result.push(value[i]);
          }
        }
      }
    } catch (ex) {
      console.log("AppUtils : filterAllContent : Error : " + ex.toString());
    }

    return result;
  },

  isSurveyHasValidTranslations: function(currentSurvey) {
    let result = false;

    try {
      let questions = currentSurvey["questions"];
      let languagesCount = currentSurvey["supported_languages"]
        ? currentSurvey["supported_languages"].length
        : 0;

      for (let i = 0; i < questions.length; i++) {
        if (typeof questions[i]["id"] == "undefined") {
          continue;
        }

        if (questions[i]["translations"].length < languagesCount) {
          return false;
        }
      }
      result = true;
    } catch (ex) {
      console.log("utils : isSurveyHasValidTranslations : Error : " + ex);
    }

    return result;
  },

  reloadPage: function() {
    try {
      window.location = window.location.origin;
    } catch (ex) {
      console.log("AppUtils : reloadPage : error :", ex);
    }
  },

  /** to get the child clients **/
  getClientChildClients: function(allClients, selectedClient) {
    let result = [];
    try {
      if (typeof selectedClient.relation_mapping != "undefined") {
        for (let i = 0; i < selectedClient.relation_mapping.length; i++) {
          for (let j = 0; j < allClients.length; j++) {
            if (
              allClients[j].id ===
                selectedClient.relation_mapping[i].relation &&
              selectedClient.relation_mapping[i].relation_type === 1
            ) {
              result.push(allClients[j]);
              break;
            }
          }
        }
      }
    } catch (ex) {
      console.log("AppUtils : getClientChildClients : Error : ");
      console.log(ex);
    }

    return result;
  },

  /** to download the file from url **/
  downloadFile: function(url) {
    try {
      /*let tempArr = url.split("/");
            let href = document.createElement('a');
            href.href = url;

            try{
                href.download = tempArr[tempArr.length - 1 ];
            } catch (ex){
            }

            document.body.appendChild(href);
            href.click();
            document.body.removeChild(href);*/

      this.openInNewTab(url);
    } catch (ex) {
      console.error("AppUtils : downloadFile : ERROR : ", ex);
    }
  },

  /** to download the current page **/
  downloadCurrentPage: function(
    rootElement,
    fileName,
    callback,
    applyScale = false
  ) {
    try {
      let renderFooter = function(doc, pageNo, pageWidth, pageHeight) {
        try {
          let str = "Page " + pageNo;
          doc.page = pageNo;
          doc.setTextColor(40);
          doc.setFontSize(8);
          doc.text(str, pageWidth - 90, pageHeight - 40);
        } catch (ex) {}
      };

      /**sclae 2 is used to remove Grey boxes coming because of box-shadowing */
      const scale = applyScale ? { scale: 2 } : {};

      let inputDiv = document.getElementById(rootElement);
      html2canvas(inputDiv, scale).then((canvas) => {
        try {
          let definedWidth = 592.28;
          let definedHeight = 841.89;

          let contentWidth = canvas.width;
          let contentHeight = canvas.height;
          let pageHeight = (contentWidth / definedWidth) * definedHeight;
          let leftHeight = contentHeight;
          let position = 0;
          let imgWidth = 595.28;
          let imgHeight = (592.28 / contentWidth) * contentHeight;

          let pageData = canvas.toDataURL("image/jpeg", 1.0);
          let pdf = new jsPDF("p", "pt", "a4");
          let pageCount = 1;

          pdf.setPage(pageCount);
          if (leftHeight < pageHeight) {
            pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
          } else {
            while (leftHeight > 0) {
              pdf.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
              leftHeight -= pageHeight;
              position -= definedHeight;
              renderFooter(pdf, pageCount, definedWidth, definedHeight);
              if (leftHeight > 0) {
                pdf.addPage();
                pageCount++;
              }
            }
          }

          pdf.save(fileName);

          if (callback) {
            callback(true);
          }
        } catch (ex) {
          console.error(ex);
          if (callback) {
            callback(false);
          }
        }
      });
    } catch (ex) {
      console.error("AppUtils : downloadCurrentPage : ERROR : ", ex);
      if (callback) {
        callback(false);
      }
    }
  },

  downloadCurrentPageToPdf: async function(
    callback,
    fileName,
    companyName,
    productLabel,
    footerLabel,
    rootElement
  ) {
    try {
      let today = new Date(Date.now()).toLocaleString({ hour12: false });
      let opt = {
        margin: [130, 30, 30, 30],
        filename: fileName,
        pagebreak: { mode: ["avoid-all", "css", "legacy"] },
        image: { type: "jpeg", quality: 1.0 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "pt", format: "a2" },
      };
      let renderFooter = function(doc, pageNo, pageHeight) {
        try {
          let clientName = companyName && companyName.toUpperCase();
          let str1 = pageNo + "";
          doc.page = pageNo;
          doc.setTextColor(50);
          doc.setFillColor(240, 242, 242); /*for header background color*/
          doc.rect(28, 30, 1135, 66, "F"); /*for header background span*/
          doc.setFillColor(75, 81, 82);
          doc.rect(45, 40, 24, 24, "F");
          doc.setFontSize(14);
          doc.setFont(undefined, "bold");
          doc.text(clientName, 75, 57);
          doc.setFontSize(18);
          doc.setTextColor(75, 81, 82, 0);
          doc.text(fileName, 46, 81);
          doc.setFontSize(8);
          doc.setFont(undefined, "normal");
          doc
            .text(str1, 30, pageHeight - 25)
            .text(`${productLabel}`, 600, pageHeight - 25)
            .text(` | ${clientName}  `, 675, pageHeight - 25)
            .text(`| ${today} `, 900, pageHeight - 25)
            .text(`|  ${footerLabel}`, 1075, pageHeight - 25);
        } catch (ex) {
          console.error(
            "downloadCurrentPageToPdf : renderFooter : ERROR : ",
            ex
          );
          if (callback) {
            callback(false);
          }
        }
      };

      html2pdf()
        .from(rootElement)
        .set(opt)
        .toPdf()
        .get("pdf")
        .then(function(pdf) {
          var totalPages = pdf.internal.getNumberOfPages();
          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            let definedHeight = pdf.internal.pageSize.getHeight();
            renderFooter(pdf, i, definedHeight);
          }
        })
        .then(function() {
          if (callback) {
            callback(true);
          }
        })
        .save();
    } catch (ex) {
      console.error("AppUtils : downloadCurrentPageToPdf : ERROR : ", ex);
    }
  },

  openInNewTab: function(url) {
    try {
      let win = window.open(url, "_blank");
      win.focus();
    } catch (ex) {
      console.error("AppUtils : openInNewTab : ERROR : ", ex);
    }
  },

  getAppBaseUrl: function() {
    return baseServerUrl === "http://127.0.0.1:8040"
      ? "http://localhost:3000"
      : baseServerUrl;
  },

  getErrorStringFromResponse: function(payload) {
    let result = "";

    try {
      if (
        typeof payload != "undefined" &&
        typeof payload.data != "undefined" &&
        typeof payload.data.error != "undefined" &&
        typeof payload.data.error.message != "undefined"
      ) {
        result = payload.data.error.message;
      }
    } catch (ex) {
      console.error("getErrorStringFromResponse : ERROR : ", ex);
    }
    return result;
  },

  isResponseSuccess: function(request) {
    let result = false;

    try {
      if (
        typeof request != "undefined" &&
        typeof request.status != "undefined" &&
        (request.status === HTTP_REQUEST_STATUS_CODES.OK ||
          request.status === HTTP_REQUEST_STATUS_CODES.CREATED)
      ) {
        result = true;
      }
    } catch (ex) {
      console.error("isResponseSuccess : ERROR : ", ex);
    }

    return result;
  },

  /** to render the element in full screen **/
  openFullscreen: function(idVal) {
    try {
      let elem = document.getElementById(idVal);

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
    } catch (ex) {
      console.error("openFullscreen : ERROR : ", ex);
    }
  },

  /** to exit the full screen of element **/
  exitFullscreen: function(idVal) {
    try {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
    } catch (ex) {
      console.error("openFullscreen : ERROR : ", ex);
    }
  },

  /** to pass sort handler to array sort **/
  sortJSONArray: function(prop) {
    return function(a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  },
  numberWithCommas: function(x) {
    if (Number(x)) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return x;
  },
  DisplayColorEvaluation: function(score) {
    let score_displyColour = "";

    try {
      if (
        typeof score != "undefined" &&
        typeof score == "number" &&
        score >= 0
      ) {
        if (score <= 78.6) {
          score_displyColour = SCORE_DISPLAY_COLOR_YELLOW;
        } else if (score > 78.6 && score < 92.9) {
          score_displyColour = SCORE_DISPLAY_COLOR_GREEN;
        } else if (score >= 92.9) {
          score_displyColour = SCORE_DISPLAY_COLOR_BLUE;
        } else {
          score_displyColour = "";
        }
      }
    } catch (ex) {
      console.error("DisplayColorEvaluation : ERROR : ", ex);
    }

    return score_displyColour;
  },

  /** to render the qr code image for download**/
  renderQRCodeforDownload: function(value, id) {
    return (
      <QRCode
        value={value}
        id={id}
        size={480}
        level={"H"}
        includeMargin={true}
        className="float-right"
        style={{ display: "none" }}
        imageSettings={{
          src: require("../assets/images/logos/ls_logo_transparent.png"),
          x: null,
          y: null,
          height: 32,
          width: 78,
          excavate: true,
        }}
      />
    );
  },

  downloadImageFromCurrentPage: function(id, name) {
    try {
      const canvas = document.getElementById(id);
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = name + ".png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (ex) {
      console.error("downloadImageFromCurrentPage : error : ", ex);
    }
  },

  generateClientsFilterDropdown: function(data) {
    let result = [];

    try {
      // result.push({
      //     key: -1,
      //     value: -1,
      //     // label: <IntlMessages id={"DIC_CLIENTS_DROPDOWN_ALL"}/>,
      //     label: "DIC_CLIENTS_DROPDOWN_ALL",
      //     client_id: -1,
      //     isAll: true
      // });

      for (let i = 0; i < data.length; i++) {
        result.push({
          key: data[i]["id"],
          id: data[i]["id"],
          value: data[i]["client_key"] ? data[i]["client_key"] : data[i]["id"],
          label: data[i]["name"],
          name: data[i]["name"],
          status: data[i]["status"],
          client_id: data[i]["client_id"],
        });
      }
    } catch (ex) {
      console.error(ex);
    }
    return result;
  },

  generateSurveyFilterDropdown: function(data) {
    let result = [];

    try {
      // result.push({
      //     key: -1,
      //     value: -1,
      //     //label: <IntlMessages id={"DIC_SURVEY_QUICK_INSIGHTS_FILTERS_ALL"}/>,
      //     label: "DIC_SURVEY_QUICK_INSIGHTS_FILTERS_ALL",
      //     clientId: -1,
      //     isAll: true
      // });

      for (let i = 0; i < data.length; i++) {
        result.push({
          key: data[i]["survey_id"],
          id: data[i]["survey_id"],
          name: data[i]["survey_name"],
          value: data[i]["survey_id"],
          label: data[i]["survey_name"],
          sharedClients: data[i]["shared_clients"],
          clientId: data[i]["client_id"],
        });
      }
    } catch (ex) {
      console.error("generateSurveyFilterDropdown : error : ", ex);
    }

    return result;
  },

  generateQuestionSequenceOnOrderChange: function(
    onEventComplate,
    surveyTemplate
  ) {
    let questions = {};
    try {
      const sourceIndex = onEventComplate.source.index;
      const destinationIndex = onEventComplate.destination.index;

      const questionCopyListInSequence =
        surveyTemplate &&
        surveyTemplate["questions"].sort(AppUtils.sortJSONArray("sequence_id"));
      const [removed] = questionCopyListInSequence.splice(sourceIndex, 1);
      questionCopyListInSequence.splice(destinationIndex, 0, removed);

      questionCopyListInSequence.map((question, index) => {
        if (question && question.id) {
          questions[question.id.replaceAll("-", "")] = index + 1;
        }
      });
    } catch (ex) {
      console.error(ex);
    }
    return questions;
  },

  getDefaultLanguageForSurvey: function(languages, user) {
    let defaultLanguage = [];
    try {
      if (languages && languages.length > 0) {
        const userProfileLanguage = languages.find(
          (lang) => lang.language_code === user.language
        );

        if (typeof userProfileLanguage != "undefined") {
          defaultLanguage = [userProfileLanguage];
        } else {
          defaultLanguage = [
            languages.find(
              (lang) => lang.language_code === DEFAULT_LANGUAGE_CODE
            ),
          ];
        }
      } else {
        defaultLanguage.push({
          id: 1,
          key: 1,
          label: DEFAULT_LANGUAGE_LABEL,
          language_code: DEFAULT_LANGUAGE_CODE,
          value: DEFAULT_LANGUAGE_CODE,
        });
      }
    } catch (ex) {
      console.error(ex);
    }

    return defaultLanguage;
  },

  getSupportedLanguagesForQuickInsights: function(data) {
    let supportedLanguages = [];
    try {
      const languages = data.supported_languages;
      if (typeof languages != "undefined" && languages != null) {
        for (let i = 0; i < languages.length; i++) {
          supportedLanguages.push({
            value: languages[i]["language_code"],
            id: languages[i]["id"],
            key: languages[i]["id"],
            label: languages[i]["name"],
            language_code: languages[i]["language_code"],
          });
        }
      }
    } catch (ex) {
      console.error(ex);
    }

    return supportedLanguages;
  },

  getTranslationStringsForLanguage: function(
    remoteTranslations,
    currentLanguage
  ) {
    try {
      if (remoteTranslations && Object.keys(remoteTranslations).length !== 0) {
        if (typeof remoteTranslations[currentLanguage] !== "undefined") {
          return remoteTranslations[currentLanguage].data;
        } else if (remoteTranslations[DEFAULT_LANGUAGE_CODE] !== "undefined") {
          return remoteTranslations[DEFAULT_LANGUAGE_CODE].data;
        }
      }
    } catch (ex) {
      console.error("getTranslationStringsForLanguage : error : ", ex);
    }
    /**If there is a error or above 'if' condition is not satisfied
     * then the surey lib will default the strings to English
     */
  },

  getReportsDates: function(startDate, endDate, strDivider = "-") {
    let result = {
      start_date: 0,
      end_date: 0,
      start_date_str: "",
      end_date_str: "",
    };

    try {
      let tempTime;

      if (startDate) {
        tempTime = startDate;
      } else {
        tempTime = new Date();
        tempTime.setMonth(
          tempTime.getMonth() - CONNECT_DEFAULT_TIME_RANGE_DIFF
        );
      }

      tempTime = new Date(tempTime.getTime());
      result["start_date_str"] =
        tempTime.getDate() +
        strDivider +
        (tempTime.getMonth() + 1) +
        strDivider +
        tempTime.getFullYear();
      tempTime = new Date(
        tempTime.getMonth() +
          1 +
          "/" +
          tempTime.getDate() +
          "/" +
          tempTime.getFullYear()
      );
      result["start_date"] = parseInt(tempTime.getTime() / 1000);

      if (endDate) {
        tempTime = endDate;
      } else {
        tempTime = new Date();
        tempTime.setDate(tempTime.getDate() - 1);
      }

      tempTime = new Date(tempTime.getTime());
      result["end_date_str"] =
        tempTime.getDate() +
        strDivider +
        (tempTime.getMonth() + 1) +
        strDivider +
        tempTime.getFullYear();
      tempTime = new Date(
        tempTime.getMonth() +
          1 +
          "/" +
          tempTime.getDate() +
          "/" +
          tempTime.getFullYear()
      );
      result["end_date"] = parseInt(tempTime.getTime() / 1000);
    } catch (ex) {
      console.error("getReportsDates : error : ", ex);
    }

    return result;
  },

  updateSurveyTemplateDetails: function(
    template,
    isUserHasTemplateCreateAccess
  ) {
    try {
      if (typeof template.questions != "undefined") {
        template.display_question = template.questions.length;
      } else {
        template.display_question = 0;
      }

      let endDate = AppUtils.getDateLabelFromDate(template["to_date"]);

      let questionsCount = 0;

      if (typeof template["questions_count"] != "undefined") {
        questionsCount = template["questions_count"];
      } else {
        questionsCount = template["questions"].length;
      }

      template["label_start_date"] = AppUtils.getDateLabelFromDateDDMMYYYY(
        template["from_date"],
        "/"
      );
      template["label_end_date"] = AppUtils.getDateLabelFromDateDDMMYYYY(
        template["to_date"],
        "/"
      );

      template[
        "label_start_date_timestamp"
      ] = AppUtils.getDateTimestampFromDate(template["from_date"]);
      template["label_end_date_timestamp"] = AppUtils.getDateTimestampFromDate(
        template["to_date"]
      );

      if (template["label_end_date"] !== "") {
        template["label_end_date"] = "  -  " + template["label_end_date"];
      }

      template["label_question_count"] = questionsCount;
      template["label_response_count"] = 0;
      let label_status_value = "";
      let label_status_style = "text-white bg-success";
      let badge_status_style = "";
      let optionsMenu = [];
      let isResponsesEnabled = false;
      let isReportsEnabled = false;
      let surveyCount = 0;
      let labelStyle = "surveyStatusLabel surveyStatusLabelDraft";

      if (typeof template["response_count"] != "undefined") {
        template["response_count"] = this.numberWithCommas(
          template["response_count"]
        );
      } else {
        template["response_count"] = 0;
      }

      if (typeof template["survey_count"] != "undefined") {
        surveyCount = template["survey_count"];
      }

      switch (template["status"]) {
        /** draft **/
        case SURVEY_STATUSES_VALUE.DRAFT: {
          label_status_value = "DIC_SURVEY_STATUS_DRAFTS";
          label_status_style = "text-white bg-warning";
          badge_status_style = "draft";
          labelStyle = "surveyStatusLabel surveyStatusLabelDraft";

          optionsMenu = SURVEY_TEMPLATE_OPTIONS_MENU["DRAFT"];
          optionsMenu = JSON.parse(JSON.stringify(optionsMenu));

          if (questionsCount > 0) {
            optionsMenu.push({
              label: "DIC_SURVEY_TEMPLATE_OPTIONS_MENU_ACTIVATE",
              value: SURVEY_TEMPLATE_VIEW_ACTIONS.ACTIVATE,
            });
          }

          break;
        }

        /** active **/
        case SURVEY_STATUSES_VALUE.ACTIVE: {
          let startDate = new Date(template["from_date"]);
          let endDate =
            template["to_date"] !== "" && template["to_date"] !== null
              ? new Date(template["to_date"])
              : -1;

          let today = new Date();

          labelStyle = "surveyStatusLabel surveyStatusLabelActive";

          if (startDate > today) {
            label_status_value = "DIC_SURVEY_STATUS_SCHEDULED";
            label_status_style = "text-white bg-primary";
            badge_status_style = "scheduled";

            optionsMenu = SURVEY_TEMPLATE_OPTIONS_MENU["SCHEDULED"];
            optionsMenu = JSON.parse(JSON.stringify(optionsMenu));

            template["optionsMenuViewAssigned"] = JSON.parse(
              JSON.stringify(
                SURVEY_TEMPLATE_OPTIONS_MENU["SCHEDULED_ASSIGNED_LIST"]
              )
            );

            template["is_schedule"] = true;
            template["is_questions_disabled"] = true;
          } else {
            let optionsMenuViewAssigned = JSON.parse(
              JSON.stringify(
                SURVEY_TEMPLATE_OPTIONS_MENU["ACTIVE_ASSIGNED_LIST"]
              )
            );

            let tempArray = [];
            tempArray.push({
              label: "DIC_SURVEY_TEMPLATE_OPTIONS_MENU_VIEW",
              value: SURVEY_TEMPLATE_VIEW_ACTIONS.VIEW,
            });

            for (let i = 0; i < optionsMenuViewAssigned.length; i++) {
              tempArray.push(optionsMenuViewAssigned[i]);
            }

            template["optionsMenuViewAssigned"] = tempArray;
            template["optionsMenuAssigned"] = JSON.parse(
              JSON.stringify(
                SURVEY_TEMPLATE_OPTIONS_MENU["ACTIVE_ASSIGNED_LIST"]
              )
            );

            optionsMenu = JSON.parse(
              JSON.stringify(SURVEY_TEMPLATE_OPTIONS_MENU["ACTIVE"])
            );
            isReportsEnabled = true;

            template["is_questions_disabled"] = true;
            isResponsesEnabled = true;

            if (endDate === -1) {
              if (today >= startDate) {
                label_status_value = "DIC_SURVEY_STATUS_ACTIVE";
                label_status_style = "text-white bg-success";
                badge_status_style = "active";
              }
            } else {
              today = AppUtils.getDateFromDateTime(today);
              startDate = AppUtils.getDateFromDateTime(startDate);
              endDate = AppUtils.getDateFromDateTime(endDate);

              if (today >= startDate && today <= endDate) {
                label_status_value = "DIC_SURVEY_STATUS_ACTIVE";
                label_status_style = "text-white bg-success";
                badge_status_style = "active";
              } else if (today > endDate) {
                label_status_value = "DIC_SURVEY_STATUS_COMPLETED";
                label_status_style = "text-white bg-indigo";
                badge_status_style = "complete";

                optionsMenu = SURVEY_TEMPLATE_OPTIONS_MENU["COMPLETED"];
                optionsMenu = JSON.parse(JSON.stringify(optionsMenu));
              }
            }
          }

          break;
        }

        /** inactive **/
        case SURVEY_STATUSES_VALUE.INACTIVE: {
          optionsMenu = SURVEY_TEMPLATE_OPTIONS_MENU["INACTIVE"];
          optionsMenu = JSON.parse(JSON.stringify(optionsMenu));

          label_status_value = "DIC_SURVEY_STATUS_INACTIVE";
          label_status_style = "text-white bg-dark";
          badge_status_style = "inactive";
          template["is_questions_disabled"] = true;

          labelStyle = "surveyStatusLabel surveyStatusLabelClosed";

          break;
        }

        /** scheduled **/
        case SURVEY_STATUSES_VALUE.SCHEDULED: {
          optionsMenu = SURVEY_TEMPLATE_OPTIONS_MENU["SCHEDULED"];
          optionsMenu = JSON.parse(JSON.stringify(optionsMenu));

          label_status_value = "DIC_SURVEY_STATUS_SCHEDULED";
          label_status_style = "text-white bg-primary";
          badge_status_style = "scheduled";
          template["is_questions_disabled"] = true;

          labelStyle = "surveyStatusLabel surveyStatusLabelActive";

          break;
        }

        /** deleted **/
        case SURVEY_STATUSES_VALUE.DELETED: {
          label_status_value = "DIC_SURVEY_STATUS_DELETED";
          label_status_style = "text-white bg-danger";
          badge_status_style = "deleted";

          labelStyle = "surveyStatusLabel surveyStatusLabelClosed";

          break;
        }

        /** completed **/
        case SURVEY_STATUSES_VALUE.COMPLETED: {
          optionsMenu = SURVEY_TEMPLATE_OPTIONS_MENU["COMPLETED"];
          optionsMenu = JSON.parse(JSON.stringify(optionsMenu));

          label_status_value = "DIC_SURVEY_STATUS_COMPLETED";
          label_status_style = "text-white bg-indigo";
          badge_status_style = "completed";
          template["is_questions_disabled"] = true;
          isResponsesEnabled = true;
          isReportsEnabled = true;

          labelStyle = "surveyStatusLabel surveyStatusLabelClosed";
          break;
        }
      }

      if (isUserHasTemplateCreateAccess) {
        if (questionsCount > 0) {
          optionsMenu.push({
            label: "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_CLONE",
            value: SURVEY_TEMPLATE_VIEW_ACTIONS.CLONE,
          });
        }

        if (isReportsEnabled) {
          optionsMenu.push({
            label: "DIC_SURVEY_TEMPLATE_OPTIONS_MENU_REPORT",
            value: SURVEY_TEMPLATE_VIEW_ACTIONS.REPORT,
          });
        }
      } else {
        let tempOptions = [];
        let currentValue;
        for (let i = 0; i < optionsMenu.length; i++) {
          currentValue = optionsMenu[i]["value"];
          if (
            currentValue === SURVEY_TEMPLATE_VIEW_ACTIONS.CREATE_SURVEY ||
            currentValue === SURVEY_TEMPLATE_VIEW_ACTIONS.VIEW
          ) {
            tempOptions.push(optionsMenu[i]);
          }
        }
        optionsMenu = tempOptions;
      }

      template["label_status_value"] = label_status_value;
      template["label_status_style"] = label_status_style;
      template["badge_status_style"] = badge_status_style;
      template["label_parent"] = "parent";
      template["is_view_factories"] = true;
      template["is_parent_factories"] = true;
      template["is_responses_enabled"] = isResponsesEnabled;
      template["labelStyle"] = labelStyle;

      let totalColumns = 5;

      if (
        typeof template["factories"] === "undefined" ||
        template["factories"].length === 0
      ) {
        template["is_view_factories"] = false;
      }

      if (
        typeof template["parent"] === "undefined" ||
        template["parent"] === ""
      ) {
        template["is_parent_factories"] = false;
      }

      template["columnWidth"] = {
        width: "25%",
      };

      template["columnWidthHidden"] = {
        width: "25%",
        opacity: "0",
      };

      let tempObj = [];
      tempObj.push({
        label: <IntlMessages id="DIC_SURVEY_SURVEY_NEW_SURVEY_VIEW" />,
        value: SURVEY_TEMPLATE_VIEW_ACTIONS.VIEW,
      });

      if (!AppUtils.selectClientModules.questionnaireReport) {
        for (let i = 0; i < optionsMenu.length; i++) {
          if (optionsMenu[i]["value"] === SURVEY_TEMPLATE_VIEW_ACTIONS.REPORT) {
            optionsMenu.splice(i, 1);
            break;
          }
        }
      }

      AppUtils.filterSupportedLanguages(template);

      for (let i = 0; i < template["supported_languages"].length; i++) {
        template["supported_languages"][i]["value"] =
          template["supported_languages"][i]["language_code"];
      }

      for (let i = 0; i < optionsMenu.length; i++) {
        tempObj.push(optionsMenu[i]);
      }

      template["optionsMenu"] = tempObj;
      template["optionsMenuView"] = optionsMenu;

      const responsePerDeviceLabel = template["one_response_per_device"]
        ? "DIC_SURVEY_NEW_SURVEY_LABEL_ONE_RESPONSE"
        : "DIC_SURVEY_NEW_SURVEY_LABEL_MULTIPLE_RESPONSES";

      template.infoMenu = [
        {
          id: SURVEY_INFO_CARD_ITEM_VALUES.SURVEY_PERIOD,
          title: (
            <IntlMessages id="DIC_SURVEY_TEMPLATE_INFO_MENU_LABEL_SURVEY_PERIOD" />
          ),
          icon: "calendar",
          userList: "",
          desc: [
            AppUtils.getDateLabelFromDate(template["from_date"]) +
              " - " +
              (endDate !== "" ? endDate : ""),
          ],
        },
        {
          id: SURVEY_INFO_CARD_ITEM_VALUES.NUMBER_OF_QUESTIONS,
          title: (
            <IntlMessages id="DIC_SURVEY_TEMPLATE_INFO_MENU_NO_OF_QUESTIONS" />
          ),
          icon: "view-list-alt",
          userList: "",
          desc: [questionsCount],
        },
        {
          id: SURVEY_INFO_CARD_ITEM_VALUES.LANGUAGES,
          title: (
            <IntlMessages id="DIC_SURVEY_TEMPLATE_INFO_MENU_NO_OF_LANGUAGES" />
          ),
          icon: "globe-alt",
          userList: "",
          desc: [
            template.supported_languages.length > 0
              ? template.supported_languages.length
              : 0,
          ],
        },
        // {
        //     id: SURVEY_INFO_CARD_ITEM_VALUES.RESPONSES_PER_DEVICE,
        //     title: <IntlMessages id="DIC_SURVEY_NEW_SURVEY_NO_OF_RESPONSES_PER_DEVICE"/>,
        //     icon: "smartphone",
        //     userList: "",
        //     desc: [responsePerDeviceLabel]
        // },
        {
          id: SURVEY_INFO_CARD_ITEM_VALUES.NUMBER_OF_CATEGORIES,
          title: (
            <IntlMessages id="DIC_SURVEY_TEMPLATE_INFO_MENU_NO_OF_CATEGORIES" />
          ),
          icon: "view-agenda",
          userList: "",
          desc: [
            template.reporting_categories &&
            template.reporting_categories.length > 0
              ? template.reporting_categories.length
              : 0,
          ],
        },
        {
          id: SURVEY_INFO_CARD_ITEM_VALUES.NUMBER_OF_COMPANIES,
          title: (
            <IntlMessages id="DIC_SURVEY_TEMPLATE_INFO_MENU_NO_OF_CLIENTS" />
          ),
          icon: "city-alt",
          userList: "",
          desc: [
            template.shared_clients.length > 0
              ? template.shared_clients.length
              : 0,
          ],
        },
      ];

      template.infoLabels = [
        {
          id: SURVEY_INFO_CARD_ITEM_VALUES.SURVEY_PERIOD,
          title: "DIC_SURVEY_NEW_SURVEY_LABEL_QUESTIONNAIRE_PERIOD",
          icon: "ls_icon_calender",
          desc: template["label_start_date"] + template["label_end_date"],
        },
        {
          id: SURVEY_INFO_CARD_ITEM_VALUES.NUMBER_OF_QUESTIONS,
          title: "DIC_SURVEY_NEW_SURVEY_NO_OF_QUESTIONS",
          icon: "ls_icon_questions",
          userList: "",
          desc: questionsCount,
        },
        {
          id: SURVEY_INFO_CARD_ITEM_VALUES.LANGUAGES,
          title: "DIC_SURVEY_NEW_SURVEY_NO_OF_LANGUAGES",
          icon: "ls_icon_languages",
          userList: "",
          desc:
            template.supported_languages.length > 0
              ? template.supported_languages.length
              : 0,
        },
        {
          id: SURVEY_INFO_CARD_ITEM_VALUES.NUMBER_OF_SURVEYS,
          title: "DIC_SURVEY_QUESTIONNAIRE_NEW_SURVEY_NO_OF_CATEGORIES",
          icon: "ls_icon_surveys",
          userList: "",
          desc:
            typeof surveyCount == "object" && surveyCount.length > 0
              ? surveyCount[0]
              : surveyCount,
        },
        // {
        //     id: SURVEY_INFO_CARD_ITEM_VALUES.RESPONSES_PER_DEVICE,
        //     title: "DIC_SURVEY_NEW_SURVEY_NO_OF_RESPONSES_PER_DEVICE",
        //     icon: "ls_icon_responses_device",
        //     userList: "",
        //     desc: responsePerDeviceLabel
        // },
        {
          id: SURVEY_INFO_CARD_ITEM_VALUES.NUMBER_OF_CATEGORIES,
          title: "DIC_SURVEY_NEW_SURVEY_NO_OF_CATEGORIES",
          icon: "ls_icon_categories",
          userList: "",
          desc:
            template.reporting_categories &&
            template.reporting_categories.length > 0
              ? template.reporting_categories.length
              : 0,
        },
        {
          id: SURVEY_INFO_CARD_ITEM_VALUES.NUMBER_OF_COMPANIES,
          title: "DIC_SURVEY_NEW_SURVEY_NO_OF_CLIENTS",
          icon: "ls_icon_companies",
          userList: "",
          desc:
            template.shared_clients.length > 0
              ? template.shared_clients.length
              : 0,
        },
      ];
    } catch (ex) {
      console.error("updateSurveyTemplateDetails : error : ", ex);
    }
    return template;
  },

  getDefaultDateRangeSelection: function() {
    let result = {};

    try {
      let todayDate = new Date();
      todayDate.setDate(todayDate.getDate() - 1);
      let endDateValue = new Date(todayDate.getTime());

      todayDate = new Date();
      todayDate.setMonth(
        todayDate.getMonth() - CONNECT_DEFAULT_TIME_RANGE_DIFF
      );

      let startDateValue = new Date(todayDate.getTime());

      result = {
        startDate: startDateValue,
        endDate: endDateValue,
        key: "selection",
      };
    } catch (ex) {
      console.error("getDefaultDateRangeSelection : error : ", ex);
    }

    return result;
  },

  getAverageScoreLabel: function(score) {
    let label = "NA";

    try {
      if (typeof score != "undefined") {
        if (typeof score == "number" && score >= 0) {
          return score.toFixed(1);
        } else {
          return score;
        }
      }
    } catch (ex) {
      console.error("getAverageScoreLabel : ERROR : ", ex);
    }

    return label;
  },

  sortJSONListByKey: function(array, key) {
    try {
      return array.sort(function(a, b) {
        let x = a[key];
        let y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    } catch (ex) {
      console.error("sortJSONListByKey : ERROR : ", ex);
    }

    return array;
  },

  getUUID: function() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  },

  getJSONObjectLength: function(obj) {
    try {
      return Object.keys(obj).length;
    } catch (ex) {
      console.error("getJSONObjectLength : error : ", ex);
    }

    return 0;
  },

  removeSpecialCharFromString: function(str) {
    try {
      if (str && typeof str === "string") {
        let format = /[!@#$%^&*()+=\[\]{};':"\\|,.<>\/?]+/g;

        str = str.replace(".", "_");
        if (format.test(str)) {
          return str.replaceAll(format, "");
        }
      }
    } catch (ex) {
      console.error("removeSpecialCharFromString : ERROR : ", ex);
    }

    return str;
  },

  getLoader: function() {
    return (
      <div className="row justify-content-center">
        <CircularProgress color="secondary" className="mr-2" size={20} />
      </div>
    );
  },

  getLinearLoader: function() {
    return <LinearProgress color="secondary" mode="query" />;
  },

  getBronzeSticker: function() {
    return <span className="reportStickerBronze" />;
  },

  getSilverSticker: function() {
    return <span className="reportStickerSilver" />;
  },

  getGoldSticker: function() {
    return <span className="reportStickerGold" />;
  },

  redirectToValidDomain: function(path) {
    try {
      if (
        window.location.hostname.indexOf("localhost") === -1 &&
        baseServerUrl.indexOf(window.location.hostname) === -1
      ) {
        window.location =
          baseServerUrl + (typeof path != "undefined" ? path : "");
      }
    } catch (ex) {
      console.log("redirectToValidDomain : error : ", ex);
    }
  },

  getRedeployedSurveys: function(surveys, data) {
    try {
      if (data) {
        let indexId = "";

        for (let i = 0; i < data.length; i++) {
          indexId =
            typeof data[i]["parent_id"] != "undefined" &&
            data[i]["parent_id"] !== ""
              ? data[i]["parent_id"]
              : "";

          data[i] = AppUtils.updateCurrentSurvey(data[i]);

          if (indexId === "") {
            indexId = data[i]["id"];
            data[i]["isRedeployed"] = false;
          } else {
            data[i]["isRedeployed"] = true;
          }

          indexId = indexId.toString();

          if (typeof surveys[indexId] == "undefined") {
            surveys[indexId] = data[i];
          } else {
            if (typeof surveys[indexId]["childs"] == "undefined") {
              surveys[indexId]["childs"] = [];
            }

            data[i]["isRedeployed"] = true;
            surveys[indexId]["childs"].push(data[i]);
          }
        }
      }
    } catch (ex) {
      console.error("getRedeployedSurveys : error : ", ex);
    }

    return surveys;
  },

  redirectDownloadProdToReports: function(url) {
    try {
      if (url.indexOf("wovonew.") !== -1) {
        url = url.replace("wovonew.", "wovonewreports.");
      }
    } catch (ex) {
      console.error("redirectDownloadProdToReports : error : ", ex);
    }

    return url;
  },

  getRedeployedTemplates: function(
    templates,
    data,
    isUserHasTemplateCreateAccess
  ) {
    try {
      if (data) {
        let indexId = "";

        for (let i = 0; i < data.length; i++) {
          indexId =
            typeof data[i]["parent_id"] != "undefined" &&
            data[i]["parent_id"] !== "" &&
            data[i]["parent_id"] !== null
              ? data[i]["parent_id"]
              : "";

          data[i] = AppUtils.updateSurveyTemplateDetails(
            data[i],
            isUserHasTemplateCreateAccess
          );

          if (indexId === "") {
            indexId = data[i]["id"];
            data[i]["isRedeployed"] = false;
          } else {
            data[i]["isRedeployed"] = true;
          }

          indexId = indexId.toString();

          if (typeof templates[indexId] == "undefined") {
            templates[indexId] = data[i];
          } else {
            if (typeof templates[indexId]["childs"] == "undefined") {
              templates[indexId]["childs"] = [];
            }

            data[i]["isRedeployed"] = true;
            templates[indexId]["childs"].push(data[i]);
          }
        }
      }
    } catch (ex) {
      console.error("getRedeployedTemplates : error : ", ex);
    }

    return templates;
  },

  getSelectedELearningLessonData: function(lessonsData) {
    let result = {
      scorm: [],
      non_scorm: [],
    };

    try {
      if (typeof lessonsData != "undefined") {
        for (let i = 0; i < lessonsData.length; i++) {
          if (lessonsData[i].selected) {
            if (lessonsData[i].isScorm) {
              result["scorm"].push(parseInt(lessonsData[i].id + ""));
            } else {
              result["non_scorm"].push(parseInt(lessonsData[i].id + ""));
            }
          }
        }
      }
    } catch (ex) {
      console.error("getSelectedELearningLessonData : error : ", ex);
    }

    return result;
  },

  generateClientsLookup: function(clients) {
    let result = {};

    try {
      if (clients && clients.length > 0) {
        result[-1] = {
          key: -1,
          value: -1,
          label: "DIC_CLIENTS_DROPDOWN_ALL",
          client_id: -1,
        };

        for (let i = 0; i < clients.length; i++) {
          if (clients[i]["client_key"]) {
            result[clients[i]["client_key"]] = {
              key: clients[i]["id"],
              value: clients[i]["client_key"]
                ? clients[i]["client_key"]
                : clients[i]["id"],
              label: clients[i]["name"],
              client_id: clients[i]["client_id"],
            };
          }
        }
      }
    } catch (ex) {
      console.error("generateClientsLookup : error : ", ex);
    }
    return result;
  },

  getAlphaNumericString: function(strValue) {
    try {
      return strValue.replace(/[^0-9a-z]/gi, "");
    } catch (ex) {
      console.error("getAlphaNumericString : error : ", ex);
    }
    return "";
  },

  isValidPhoneNumber: function(value) {
    try {
      let isValid = /^[0-9 ()+]+$/.test(value);

      if (isValid) {
        let occurrences = (value.match(/\+/g) || []).length;
        let indexValue = value.indexOf("+");

        if (occurrences !== 0) {
          if (occurrences === 1) {
            if (indexValue !== 0) {
              isValid = false;
            }
          } else {
            isValid = false;
          }
        }
      }

      return isValid;
    } catch (ex) {
      console.error("isValidPhoneNumber : error : ", ex);
    }
    return false;
  },

  getEmojiListLength: function() {
    return Object.keys(SURVEY_QUESTION_EMOJI_COUNT_CONFIG).length;
  },

  validateEmail: function(text) {
    try {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      return reg.test(text);
    } catch (ex) {
      console.error("validateEmail : error : ", ex);
    }

    return false;
  },

  convertDateToGivenFormat: function(date, format, userTimeZone) {
    let convertedDate = "";

    try {
      if (typeof date !== "undefined" && date !== null && date !== "") {
        convertedDate = moment.tz(date, userTimeZone).format(format);
      }
    } catch (err) {
      console.error("convertDateToGivenFormat : error : ", err);
    }
    return convertedDate;
  },

  AddRandomUTCHoursToDate: function(date) {
    try {
      if (typeof date !== "undefined" && date !== null && date !== "") {
        let randomHour = this.getRandomIntInclusive(0, 12);

        if (randomHour.toString().length === 1) {
          randomHour = `0${randomHour}`;
        }

        return `${date}T${randomHour}:00:00.000Z`;
      }
    } catch (err) {
      console.error("AddRandomUTCHoursToDate : error : ", err);
    }
  },

  getRandomIntInclusive: function(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
  },

  /**need to pass javascript date object only */
  differenceBtwTwoDatesInHrs: function(startDate, endDate) {
    try {
      if (startDate && endDate) {
        const hrs = (endDate.getTime() - startDate.getTime()) / (1000 * 3600);
        const ronded_hrs = Math.round(hrs * 2) / 2;
        return ronded_hrs;
      }
    } catch (err) {
      console.error("differenceBtwTwoDatesInHrs : error : ", err);
    }
  },

  /**converts the url query params to a base64 string and returns the url along with crc*/
  base64EncodedParamsWithUrl: function(url) {
    let encodedUrl = url;

    try {
      if (url) {
        const urlObject = new URL(url);

        const searchParam = urlObject.search;

        if (searchParam) {
          const crc = crc32(searchParam).toString();
          const encodedParams = btoa(searchParam);
          encodedUrl = `${urlObject.origin}${urlObject.pathname}?c=${crc}&q=${encodedParams}`;
        }
      }
    } catch (err) {
      console.error("base64EncodedParamsWithUrl : error : ", err);
    }
    return encodedUrl;
  },
};

export default AppUtils;
